import Vue from "vue";

/**
 * 授权方式
 */
export const AuthorizationRequired = [
  {
    id: 1,
    dictType: "authorization_required",
    value: true,
    dictCode: true,
    label: Vue.prototype.$t("backCheck.authOnline"),
    dictName: Vue.prototype.$t("backCheck.authOnline"),
  },
  {
    id: 2,
    dictType: "authorization_required",
    value: false,
    dictCode: false,
    label: Vue.prototype.$t("backCheck.authOffline"),
    dictName: Vue.prototype.$t("backCheck.authOffline"),
  },
];

/**
 * 背调订单产品类型
 */
export const RC_ORDER_PRODUCT_TYPE = {
  /**
   * 专业资格证书验证
   */
  EducationBackground: {
    Id: 7,
    Name: "教育背景",
    NameEn: "Education Background",
  },

  /**
   * 专业资格证书验证
   */
  CertVerification: {
    Id: 8,
    Name: "专业资格证书验证",
    NameEn: "Professional qualification certificate validation",
  },

  /**
   * 工作履历核实
   */
  EmploymentBackground: {
    Id: 10,
    Name: "工作履历核实",
    NameEn: "Employment Background",
  },

  /**
   * 工作表现鉴定(主观评价版)
   */
  JobPerformance_SubjectiveAssessment: {
    Id: 11,
    Name: "工作表现鉴定(主观评价版)",
    NameEn: "Job Performance",
  },

  /**
   * 有限诉讼记录查询
   */
  CivilLitigationRecord: {
    Id: 13,
    Name: "有限诉讼记录查询",
    NameEn: "Civil Litigation Record",
  },

  /**
   * 工作表现鉴定(行为特征版)
   */
  JobPerformance_BehaviorCharacteristics: {
    Id: 31,
    Name: "工作表现鉴定(行为特征版)",
    NameEn: "Job Performance",
  },

  /**
   * 个人对外投资与任职及法人信息查询
   */
  BusinessConflictQuery: {
    Id: 33,
    Name: "个人对外投资与任职及法人信息查询",
    NameEn: "Commercial Conflict Verification",
  },

  /**
   * 工作表现鉴定(决策版)
   */
  JobPerformance_Decision: {
    Id: 40,
    Name: "工作表现鉴定(决策版)",
    NameEn: "Job Performance",
  },

  /**
   * 学历信息核实
   */
  EducationBackgroundStandalone: {
    Id: 49,
    Name: "学历信息核实",
    NameEn: "Education Background",
  },

  /**
   * 失信风险检索
   */
  RiskOfCreditInquiry: {
    Id: 50,
    Name: "失信风险检索",
    NameEn: "Risk Of Credit Inquiry",
  },

  /**
   * 限制消费检索
   */
  LimitConsumptionInquiry: {
    Id: 51,
    Name: "限制消费检索",
    NameEn: "Limit Consumption Inquiry",
  },

  /**
   * 涉诉信息检索
   */
  LitigationInformationInquiry: {
    Id: 52,
    Name: "涉诉信息检索",
    NameEn: "Litigation Issue Inquiry",
  },

  /**
   * 金融黑名单检索
   */
  FinancialBlockListInquiry: {
    Id: 59,
    Name: "金融黑名单检索",
    NameEn: "Financial BlockList Inquiry",
  },

  /**
   * 逾期记录检索
   */
  OverdueRecordEnquiry: {
    Id: 63,
    Name: "逾期记录检索",
    NameEn: "Overdue Record Enquiry",
  },

  /**
   * 逾期记录检索（概述）
   */
  OverdueRecordEnquirySummary: {
    Id: 64,
    Name: "逾期记录检索（概述）",
    NameEn: "Overdue Record Enquiry (Summary)",
  },

  /**
   * 金融违规记录查询
   */
  FinancialViolationRecordsEnquiry: {
    Id: 65,
    Name: "金融违规记录查询",
    NameEn: "Financial Regulatory",
  },
};

<template>
    <div>
        <div class="order-report ec-detail ec-card" v-if="reportPublished">
            <a-spin :spinning="loading">
                <!-- 基本信息 -->
                <div class="card-title">
                    <span class="card-title-real">{{ $t("order.report.info") }}</span>
                </div>

                <div class="card-body">
                    <a-descriptions bordered :column="2" class="order-report-base-info">
                        <a-descriptions-item :label="$t('backCheck.report.client')">
                            {{ reportContent.customerName }}
                        </a-descriptions-item>

                        <a-descriptions-item :label="$t('backCheck.cname')">
                            {{ reportContent.candidateName }}
                        </a-descriptions-item>

                        <a-descriptions-item :label="$t('order.filter.number')">
                            {{ reportContent.reportSerialNo }}
                        </a-descriptions-item>

                        <a-descriptions-item :label="$t('order.detail.sex')">
                            <span v-if="$store.getters.isChinese">
                                {{ reportContent.candidateGender }}
                            </span>

                            <span v-else>
                                {{ reportContent.candidateGenderMutipleLang }}
                            </span>
                        </a-descriptions-item>

                        <a-descriptions-item :label="$t('backCheck.report.idCard')">
                            {{ reportContent.candidateIdCardNo }}
                        </a-descriptions-item>

                        <a-descriptions-item :label="$t('present.info.birth')">
                            {{ reportContent.candidateBirthday }}
                        </a-descriptions-item>

                        <a-descriptions-item :label="$t('order.filter.status')">
                            <span v-if="$store.getters.isChinese">
                                {{ reportContent.reportStatus }}
                            </span>

                            <span v-else>
                                {{ reportContent.reportStatusMutipleLang }}
                            </span>
                        </a-descriptions-item>

                        <a-descriptions-item :label="$t('backCheck.report.submitDate')">
                            {{ reportContent.submissionDate }}
                        </a-descriptions-item>

                        <a-descriptions-item :label="$t('order.filter.level')">
                            <img style="width: 18px;" :src="shieldImages[reportContent.reportLevel]"
                                :alt="reportContent.shieldCode" />
                        </a-descriptions-item>
                    </a-descriptions>
                </div>

                <!-- 概述 -->
                <div class="card-title">
                    <span class="card-title-real">{{ $t("order.report.view") }}</span>
                </div>

                <div class="card-body">
                    <a-table :pagination="false" rowKey="id" :columns="reportSummaryTableColumn"
                        :data-source="reportSummaryTableData" bordered>
                        <template slot="productName" slot-scope="text, record">
                            <span v-if="$store.getters.isChinese">{{ record.productName }}</span>
                            <span v-else>{{ record.productNameMutipleLang }}</span>

                            <!--履历/学历-->
                            <span
                                v-if="[productTypes.EducationBackground.Id, productTypes.EducationBackgroundStandalone.Id, productTypes.EmploymentBackground.Id].includes(record.productNo)">
                                <span> - </span>
                                <span style="font-weight: bold;" v-if="$store.getters.isChinese">
                                    {{ record.summary }}
                                </span>
                                <span style="font-weight: bold;" v-else>{{ record.summaryMutipleLang }}</span>
                            </span>
                        </template>

                        <template slot="summaryStateText" slot-scope="text, record">
                            <span v-if="$store.getters.isChinese">{{ record.summaryStateText }}</span>
                            <span v-else>{{ record.summaryStateTextMutipleLang }}</span>
                        </template>

                        <template slot="summaryValue" slot-scope="text, record">
                            <span :style="{
                                color: `rgb(${record.summaryColor.red},${record.summaryColor.green},${record.summaryColor.blue})`
                            }">

                                <span v-if="$store.getters.isChinese === false && record.summaryValueMutipleLang">
                                    {{ record.summaryValueMutipleLang }}
                                </span>
                                <span v-else>
                                    {{ text }}
                                </span>
                            </span>
                        </template>

                        <template slot="shieldImg" slot-scope="text, record">
                            <img style="width: 18px;" :src="shieldImages[text]" :alt="record.shieldCode" />
                        </template>
                    </a-table>
                </div>

                <!--详情-->
                <div v-for="(item, index) in reportModule" :key="index">
                    <div class="card-title">
                        <span class="card-title-real" style="display: flex; align-items: center;">
                            <span style="margin-right: 5px;">
                                <span v-if="$store.getters.isChinese">{{ item.productName }}</span>
                                <span v-else>{{ item.productNameMutipleLang }}</span>
                            </span>

                            <!--等级图片-->
                            <img style="height: 1.2em;" :src="shieldImages[item.shieldImg]" :alt="item.shieldCode" />

                            <span style="display: inline-flex; width: 0.5em;"></span>

                            <!--概述状态-->
                            <a-tag>
                                <span v-if="$store.getters.isChinese">{{ item.summaryStateText }}</span>
                                <span v-else>{{ item.summaryStateTextMutipleLang }}</span>
                            </a-tag>

                            <!--概述结果-->
                            <a-tag>
                                <span :style="{
                                    color: `rgb(${item.summaryColor.red},${item.summaryColor.green},${item.summaryColor.blue})`
                                }">
                                    <span v-if="$store.getters.isChinese === false && item.summaryValueMutipleLang">
                                        {{ item.summaryValueMutipleLang }}
                                    </span>
                                    <span v-else>
                                        {{ item.summaryValue }}
                                    </span>
                                </span>
                            </a-tag>
                        </span>
                    </div>

                    <div class="card-body">
                        <!--专业资格-->
                        <div class="ant-table ant-table-scroll-position-left ant-table-default ant-table-bordered"
                            v-if="item.productNo === productTypes.CertVerification.Id">
                            <div style="display: none;">
                                {{certCompletedDateQuestions = item.questions.filter(x => x.question === "核实时间")}}
                                {{certListQuestions = item.questions.filter(x => x.question === "专业资格证书")}}
                                {{ certDataList= (certListQuestions
                                    && certListQuestions[0]
                                    && certListQuestions[0].summary
                                    && certListQuestions[0].summary.startsWith("["))
                                    ? JSON.parse(certListQuestions[0].summary) :
                                    undefined }}
                            </div>

                            <div class="ant-table-content">
                                <div class="ant-table-body">
                                    <table>
                                        <colgroup>
                                            <col style="width: 100px; min-width: 100px;">
                                            <col style="width: 100px; min-width: 100px;">
                                            <col style="width: 100px; min-width: 100px;">
                                            <col style="width: 100px; min-width: 100px;">
                                        </colgroup>

                                        <thead class="ant-table-thead">
                                            <tr>
                                                <th class="ant-table-align-center ant-table-row-cell-break-word"
                                                    style="text-align: center;">
                                                    <span class="ant-table-header-column">
                                                        <div>
                                                            <span class="ant-table-column-title">
                                                                {{ $t("backCheck.report.certification.title") }}
                                                            </span>
                                                            <span class="ant-table-column-sorter"></span>
                                                        </div>
                                                    </span>
                                                </th>
                                                <th class="ant-table-align-center ant-table-row-cell-break-word"
                                                    style="text-align: center;">
                                                    <span class="ant-table-header-column">
                                                        <div>
                                                            <span class="ant-table-column-title">
                                                                {{ $t("backCheck.report.certification.organization") }}
                                                            </span>
                                                            <span class="ant-table-column-sorter"></span>
                                                        </div>
                                                    </span>
                                                </th>
                                                <th class="ant-table-align-center ant-table-row-cell-break-word"
                                                    style="text-align: center;">
                                                    <span class="ant-table-header-column">
                                                        <div>
                                                            <span class="ant-table-column-title">
                                                                {{ $t("backCheck.report.certification.number") }}
                                                            </span>
                                                            <span class="ant-table-column-sorter"></span>
                                                        </div>
                                                    </span>
                                                </th>
                                                <th class="ant-table-align-center ant-table-row-cell-break-word"
                                                    style="text-align: center;">
                                                    <span class="ant-table-header-column">
                                                        <div>
                                                            <span class="ant-table-column-title">
                                                                {{ $t("backCheck.report.certification.time") }}
                                                            </span>
                                                            <span class="ant-table-column-sorter"></span>
                                                        </div>
                                                    </span>
                                                </th>
                                            </tr>
                                        </thead>

                                        <tbody class="ant-table-tbody">
                                            <tr class="ant-table-row ant-table-row-level-0"
                                                v-if="!certDataList || certDataList.length < 1">
                                                <td colspan="4">
                                                    <a-empty />
                                                </td>
                                            </tr>

                                            <tr v-for="(certItem, index) in certDataList" :key="index">
                                                <td class="ant-table-row-cell-break-word" style="text-align: center;">
                                                    {{ certItem.CertificationContent }}
                                                </td>
                                                <td class="ant-table-row-cell-break-word" style="text-align: center;">
                                                    {{ certItem.CertificationAuthority }}
                                                </td>
                                                <td class="ant-table-row-cell-break-word" style="text-align: center;">
                                                    {{ certItem.CertificateId }}
                                                </td>
                                                <td class="ant-table-row-cell-break-word" style="text-align: center;">
                                                    {{ certItem.CertificationDate }}
                                                </td>
                                            </tr>

                                            <tr class="ant-table-row ant-table-row-level-0"
                                                v-if="certCompletedDateQuestions">
                                                <td class="ant-table-row-cell-break-word" style="text-align: center;">
                                                    <span v-if="$store.getters.isChinese">
                                                        {{ certCompletedDateQuestions[0].question }}
                                                    </span>

                                                    <span v-else>
                                                        {{ certCompletedDateQuestions[0].questionMutipleLang }}
                                                    </span>
                                                </td>

                                                <td class="ant-table-row-cell-break-word" style="text-align: center;"
                                                    colspan="3">
                                                    <span
                                                        v-if="$store.getters.isChinese && certCompletedDateQuestions[0].summaryMutipleLang">
                                                        {{ certCompletedDateQuestions[0].summaryMutipleLang }}
                                                    </span>

                                                    <span v-else>
                                                        {{ certCompletedDateQuestions[0].summary }}
                                                    </span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <!--履历-->
                        <div v-else-if="item.productNo === productTypes.EmploymentBackground.Id">
                            <!--无数据-->
                            <!-- <div class="ant-table ant-table-scroll-position-left ant-table-default ant-table-bordered"
                                v-if="!reportResumeList || reportResumeList.length < 1">
                                <div class="ant-table-content">
                                    <div class="ant-table-body">
                                        <table>
                                            <tbody class="ant-table-tbody"
                                                v-if="!overdueData || overdueData.length < 1">
                                                <tr class="ant-table-row ant-table-row-level-0">
                                                    <td colspan="5">
                                                        <a-empty />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div> -->

                            <!--履历部分-->
                            <!--表现部分-->
                        </div>

                        <!--诉讼（老司法），暂时不处理内容-->
                        <div v-else-if="item.productNo === productTypes.CivilLitigationRecord.Id"></div>

                        <!--商冲-->
                        <div v-else-if="item.productNo === productTypes.BusinessConflictQuery.Id">
                            <div style="display:none;">
                                {{businessCompletedDateQuestions = item.questions.filter(x => x.question === "核实时间")}}

                                {{businessLegalPersonQuestion = item.questions.filter(x => x.question === "企业法人信息")}}
                                {{ businessLegalPersonDataList = (businessLegalPersonQuestion
                                    && businessLegalPersonQuestion[0]
                                    && businessLegalPersonQuestion[0].summary
                                    && businessLegalPersonQuestion[0].summary.startsWith("["))
                                    ? JSON.parse(businessLegalPersonQuestion[0].summary) :
                                    undefined }}

                                {{businessShareholderQuestion = item.questions.filter(x => x.question === "企业股东信息")}}
                                {{ businessShareholderDataList = (businessShareholderQuestion
                                    && businessShareholderQuestion[0]
                                    && businessShareholderQuestion[0].summary
                                    && businessShareholderQuestion[0].summary.startsWith("["))
                                    ? JSON.parse(businessShareholderQuestion[0].summary) :
                                    undefined }}

                                {{businessManagerQuestion = item.questions.filter(x => x.question === "企业主要管理人员信息")}}
                                {{ businessManagerDataList = (businessManagerQuestion
                                    && businessManagerQuestion[0]
                                    && businessManagerQuestion[0].summary
                                    && businessManagerQuestion[0].summary.startsWith("["))
                                    ? JSON.parse(businessManagerQuestion[0].summary) :
                                    undefined }}
                            </div>

                            <!--法人-->
                            <div class="ant-table ant-table-scroll-position-left ant-table-default ant-table-bordered">
                                <div class="ant-table-title" style="text-align: center; font-size: 1.1em;">
                                    <b>{{ $t("backCheck.report.commercialConflict.nameLegalPerson") }}</b>
                                </div>
                                <div class="ant-table-content">
                                    <div class="ant-table-body">
                                        <table>
                                            <colgroup>
                                                <col style="width: 100px; min-width: 100px;">
                                                <col style="width: 100px; min-width: 100px;">
                                                <col style="width: 100px; min-width: 100px;">
                                                <col style="width: 100px; min-width: 100px;">
                                                <col style="width: 100px; min-width: 100px;">
                                                <col style="width: 100px; min-width: 100px;">
                                            </colgroup>

                                            <thead class="ant-table-thead">
                                                <tr>
                                                    <th class="ant-table-align-center ant-table-row-cell-break-word"
                                                        style="text-align: center;">
                                                        <span class="ant-table-header-column">
                                                            <div>
                                                                <span class="ant-table-column-title">
                                                                    {{
                                                                        $t("backCheck.report.commercialConflict.columnNamePerson")
                                                                    }}
                                                                </span>
                                                                <span class="ant-table-column-sorter"></span>
                                                            </div>
                                                        </span>
                                                    </th>
                                                    <th class="ant-table-align-center ant-table-row-cell-break-word"
                                                        style="text-align: center;">
                                                        <span class="ant-table-header-column">
                                                            <div>
                                                                <span class="ant-table-column-title">
                                                                    {{
                                                                        $t("backCheck.report.commercialConflict.columnNameEnterprise")
                                                                    }}
                                                                </span>
                                                                <span class="ant-table-column-sorter"></span>
                                                            </div>
                                                        </span>
                                                    </th>
                                                    <th class="ant-table-align-center ant-table-row-cell-break-word"
                                                        style="text-align: center;">
                                                        <span class="ant-table-header-column">
                                                            <div>
                                                                <span class="ant-table-column-title">
                                                                    {{
                                                                        $t("backCheck.report.commercialConflict.columnRegNo")
                                                                    }}
                                                                </span>
                                                                <span class="ant-table-column-sorter"></span>
                                                            </div>
                                                        </span>
                                                    </th>
                                                    <th class="ant-table-align-center ant-table-row-cell-break-word"
                                                        style="text-align: center;">
                                                        <span class="ant-table-header-column">
                                                            <div>
                                                                <span class="ant-table-column-title">
                                                                    {{
                                                                        $t("backCheck.report.commercialConflict.columnRegCap")
                                                                    }}
                                                                </span>
                                                                <span class="ant-table-column-sorter"></span>
                                                            </div>
                                                        </span>
                                                    </th>
                                                    <th class="ant-table-align-center ant-table-row-cell-break-word"
                                                        style="text-align: center;">
                                                        <span class="ant-table-header-column">
                                                            <div>
                                                                <span class="ant-table-column-title">
                                                                    {{
                                                                        $t("backCheck.report.commercialConflict.columnRegCapCur")
                                                                    }}
                                                                </span>
                                                                <span class="ant-table-column-sorter"></span>
                                                            </div>
                                                        </span>
                                                    </th>
                                                    <th class="ant-table-align-center ant-table-row-cell-break-word"
                                                        style="text-align: center;">
                                                        <span class="ant-table-header-column">
                                                            <div>
                                                                <span class="ant-table-column-title">
                                                                    {{
                                                                        $t("backCheck.report.commercialConflict.columnStatusEnterpeise")
                                                                    }}
                                                                </span>
                                                                <span class="ant-table-column-sorter"></span>
                                                            </div>
                                                        </span>
                                                    </th>
                                                </tr>
                                            </thead>

                                            <tbody class="ant-table-tbody">
                                                <tr class="ant-table-row ant-table-row-level-0"
                                                    v-if="!businessLegalPersonDataList || businessLegalPersonDataList.length < 1">
                                                    <td colspan="6">
                                                        <a-empty />
                                                    </td>
                                                </tr>

                                                <tr v-for="(businessLegalPersonItem, index) in businessLegalPersonDataList"
                                                    :key="index" :style="{
                                                        color: `rgb(${businessLegalPersonQuestion[0].fontColorValue.red},${businessLegalPersonQuestion[0].fontColorValue.green},${businessLegalPersonQuestion[0].fontColorValue.blue})`
                                                    }">
                                                    <td class="ant-table-row-cell-break-word"
                                                        style="text-align: center;">
                                                        {{ businessLegalPersonItem.ryname }}
                                                    </td>
                                                    <td class="ant-table-row-cell-break-word"
                                                        style="text-align: center;">
                                                        {{ businessLegalPersonItem.entname }}
                                                    </td>
                                                    <td class="ant-table-row-cell-break-word"
                                                        style="text-align: center;">
                                                        {{ businessLegalPersonItem.regno }}
                                                    </td>
                                                    <td class="ant-table-row-cell-break-word"
                                                        style="text-align: center;">
                                                        {{ businessLegalPersonItem.regcap }}
                                                    </td>
                                                    <td class="ant-table-row-cell-break-word"
                                                        style="text-align: center;">
                                                        {{ businessLegalPersonItem.regcapcur }}
                                                    </td>
                                                    <td class="ant-table-row-cell-break-word"
                                                        style="text-align: center;">
                                                        {{ businessLegalPersonItem.entstatus }}
                                                    </td>
                                                </tr>

                                                <tr class="ant-table-row ant-table-row-level-0"
                                                    v-if="businessCompletedDateQuestions">
                                                    <td class="ant-table-row-cell-break-word"
                                                        style="text-align: center;">
                                                        <span v-if="$store.getters.isChinese">
                                                            {{ businessCompletedDateQuestions[0].question }}
                                                        </span>

                                                        <span v-else>
                                                            {{ businessCompletedDateQuestions[0].questionMutipleLang }}
                                                        </span>
                                                    </td>

                                                    <td class="ant-table-row-cell-break-word"
                                                        style="text-align: center;" colspan="6">
                                                        <span
                                                            v-if="$store.getters.isChinese && businessCompletedDateQuestions[0].summaryMutipleLang">
                                                            {{ businessCompletedDateQuestions[0].summaryMutipleLang }}
                                                        </span>

                                                        <span v-else>
                                                            {{ businessCompletedDateQuestions[0].summary }}
                                                        </span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                            <br>

                            <!--股东-->
                            <div class="ant-table ant-table-scroll-position-left ant-table-default ant-table-bordered">
                                <div class="ant-table-title" style="text-align: center; font-size: 1.1em;">
                                    <b>{{ $t("backCheck.report.commercialConflict.nameShareholder") }}</b>
                                </div>
                                <div class="ant-table-content">
                                    <div class="ant-table-body">
                                        <table>
                                            <colgroup>
                                                <col style="width: 100px; min-width: 100px;">
                                                <col style="width: 100px; min-width: 100px;">
                                                <col style="width: 100px; min-width: 100px;">
                                                <col style="width: 100px; min-width: 100px;">
                                                <col style="width: 100px; min-width: 100px;">
                                                <col style="width: 100px; min-width: 100px;">
                                                <col style="width: 100px; min-width: 100px;">
                                            </colgroup>

                                            <thead class="ant-table-thead">
                                                <tr>
                                                    <th class="ant-table-align-center ant-table-row-cell-break-word"
                                                        style="text-align: center;">
                                                        <span class="ant-table-header-column">
                                                            <div>
                                                                <span class="ant-table-column-title">
                                                                    {{
                                                                        $t("backCheck.report.commercialConflict.columnNamePerson")
                                                                    }}
                                                                </span>
                                                                <span class="ant-table-column-sorter"></span>
                                                            </div>
                                                        </span>
                                                    </th>
                                                    <th class="ant-table-align-center ant-table-row-cell-break-word"
                                                        style="text-align: center;">
                                                        <span class="ant-table-header-column">
                                                            <div>
                                                                <span class="ant-table-column-title">
                                                                    {{
                                                                        $t("backCheck.report.commercialConflict.columnNameEnterprise")
                                                                    }}
                                                                </span>
                                                                <span class="ant-table-column-sorter"></span>
                                                            </div>
                                                        </span>
                                                    </th>
                                                    <th class="ant-table-align-center ant-table-row-cell-break-word"
                                                        style="text-align: center;">
                                                        <span class="ant-table-header-column">
                                                            <div>
                                                                <span class="ant-table-column-title">
                                                                    {{
                                                                        $t("backCheck.report.commercialConflict.columnRegNo")
                                                                    }}
                                                                </span>
                                                                <span class="ant-table-column-sorter"></span>
                                                            </div>
                                                        </span>
                                                    </th>
                                                    <th class="ant-table-align-center ant-table-row-cell-break-word"
                                                        style="text-align: center;">
                                                        <span class="ant-table-header-column">
                                                            <div>
                                                                <span class="ant-table-column-title">
                                                                    {{
                                                                        $t("backCheck.report.commercialConflict.columnRegCap")
                                                                    }}
                                                                </span>
                                                                <span class="ant-table-column-sorter"></span>
                                                            </div>
                                                        </span>
                                                    </th>
                                                    <th class="ant-table-align-center ant-table-row-cell-break-word"
                                                        style="text-align: center;">
                                                        <span class="ant-table-header-column">
                                                            <div>
                                                                <span class="ant-table-column-title">
                                                                    {{
                                                                        $t("backCheck.report.commercialConflict.columnRegCapCur")
                                                                    }}
                                                                </span>
                                                                <span class="ant-table-column-sorter"></span>
                                                            </div>
                                                        </span>
                                                    </th>
                                                    <th class="ant-table-align-center ant-table-row-cell-break-word"
                                                        style="text-align: center;">
                                                        <span class="ant-table-header-column">
                                                            <div>
                                                                <span class="ant-table-column-title">
                                                                    {{
                                                                        $t("backCheck.report.commercialConflict.columnStatusEnterpeise")
                                                                    }}
                                                                </span>
                                                                <span class="ant-table-column-sorter"></span>
                                                            </div>
                                                        </span>
                                                    </th>
                                                    <th class="ant-table-align-center ant-table-row-cell-break-word"
                                                        style="text-align: center;">
                                                        <span class="ant-table-header-column">
                                                            <div>
                                                                <span class="ant-table-column-title">
                                                                    {{
                                                                        $t("backCheck.report.commercialConflict.columnSubCapCon")
                                                                    }}
                                                                </span>
                                                                <span class="ant-table-column-sorter"></span>
                                                            </div>
                                                        </span>
                                                    </th>
                                                </tr>
                                            </thead>

                                            <tbody class="ant-table-tbody">
                                                <tr class="ant-table-row ant-table-row-level-0"
                                                    v-if="!businessShareholderDataList || businessShareholderDataList.length < 1">
                                                    <td colspan="7">
                                                        <a-empty />
                                                    </td>
                                                </tr>

                                                <tr v-for="(businessShareholderItem, index) in businessShareholderDataList"
                                                    :key="index" :style="{
                                                        color: `rgb(${businessShareholderQuestion[0].fontColorValue.red},${businessShareholderQuestion[0].fontColorValue.green},${businessShareholderQuestion[0].fontColorValue.blue})`
                                                    }">
                                                    <td class="ant-table-row-cell-break-word"
                                                        style="text-align: center;">
                                                        {{ businessShareholderItem.ryname }}
                                                    </td>
                                                    <td class="ant-table-row-cell-break-word"
                                                        style="text-align: center;">
                                                        {{ businessShareholderItem.entname }}
                                                    </td>
                                                    <td class="ant-table-row-cell-break-word"
                                                        style="text-align: center;">
                                                        {{ businessShareholderItem.regno }}
                                                    </td>
                                                    <td class="ant-table-row-cell-break-word"
                                                        style="text-align: center;">
                                                        {{ businessShareholderItem.regcap }}
                                                    </td>
                                                    <td class="ant-table-row-cell-break-word"
                                                        style="text-align: center;">
                                                        {{ businessShareholderItem.regcapcur }}
                                                    </td>
                                                    <td class="ant-table-row-cell-break-word"
                                                        style="text-align: center;">
                                                        {{ businessShareholderItem.entstatus }}
                                                    </td>
                                                    <td class="ant-table-row-cell-break-word"
                                                        style="text-align: center;">
                                                        {{ businessShareholderItem.subconam }}
                                                    </td>
                                                </tr>

                                                <tr class="ant-table-row ant-table-row-level-0"
                                                    v-if="businessCompletedDateQuestions">
                                                    <td class="ant-table-row-cell-break-word"
                                                        style="text-align: center;">
                                                        <span v-if="$store.getters.isChinese">
                                                            {{ businessCompletedDateQuestions[0].question }}
                                                        </span>

                                                        <span v-else>
                                                            {{ businessCompletedDateQuestions[0].questionMutipleLang }}
                                                        </span>
                                                    </td>

                                                    <td class="ant-table-row-cell-break-word"
                                                        style="text-align: center;" colspan="7">
                                                        <span
                                                            v-if="$store.getters.isChinese && businessCompletedDateQuestions[0].summaryMutipleLang">
                                                            {{ businessCompletedDateQuestions[0].summaryMutipleLang }}
                                                        </span>

                                                        <span v-else>
                                                            {{ businessCompletedDateQuestions[0].summary }}
                                                        </span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                            <br>

                            <!--高管-->
                            <div class="ant-table ant-table-scroll-position-left ant-table-default ant-table-bordered">
                                <div class="ant-table-title" style="text-align: center; font-size: 1.1em;">
                                    <b>{{ $t("backCheck.report.commercialConflict.nameLegalManager") }}</b>
                                </div>
                                <div class="ant-table-content">
                                    <div class="ant-table-body">
                                        <table>
                                            <colgroup>
                                                <col style="width: 100px; min-width: 100px;">
                                                <col style="width: 100px; min-width: 100px;">
                                                <col style="width: 100px; min-width: 100px;">
                                                <col style="width: 100px; min-width: 100px;">
                                                <col style="width: 100px; min-width: 100px;">
                                                <col style="width: 100px; min-width: 100px;">
                                                <col style="width: 100px; min-width: 100px;">
                                            </colgroup>

                                            <thead class="ant-table-thead">
                                                <tr>
                                                    <th class="ant-table-align-center ant-table-row-cell-break-word"
                                                        style="text-align: center;">
                                                        <span class="ant-table-header-column">
                                                            <div>
                                                                <span class="ant-table-column-title">
                                                                    {{
                                                                        $t("backCheck.report.commercialConflict.columnNamePerson")
                                                                    }}
                                                                </span>
                                                                <span class="ant-table-column-sorter"></span>
                                                            </div>
                                                        </span>
                                                    </th>
                                                    <th class="ant-table-align-center ant-table-row-cell-break-word"
                                                        style="text-align: center;">
                                                        <span class="ant-table-header-column">
                                                            <div>
                                                                <span class="ant-table-column-title">
                                                                    {{
                                                                        $t("backCheck.report.commercialConflict.columnNameEnterprise")
                                                                    }}
                                                                </span>
                                                                <span class="ant-table-column-sorter"></span>
                                                            </div>
                                                        </span>
                                                    </th>
                                                    <th class="ant-table-align-center ant-table-row-cell-break-word"
                                                        style="text-align: center;">
                                                        <span class="ant-table-header-column">
                                                            <div>
                                                                <span class="ant-table-column-title">
                                                                    {{
                                                                        $t("backCheck.report.commercialConflict.columnRegNo")
                                                                    }}
                                                                </span>
                                                                <span class="ant-table-column-sorter"></span>
                                                            </div>
                                                        </span>
                                                    </th>
                                                    <th class="ant-table-align-center ant-table-row-cell-break-word"
                                                        style="text-align: center;">
                                                        <span class="ant-table-header-column">
                                                            <div>
                                                                <span class="ant-table-column-title">
                                                                    {{
                                                                        $t("backCheck.report.commercialConflict.columnRegCap")
                                                                    }}
                                                                </span>
                                                                <span class="ant-table-column-sorter"></span>
                                                            </div>
                                                        </span>
                                                    </th>
                                                    <th class="ant-table-align-center ant-table-row-cell-break-word"
                                                        style="text-align: center;">
                                                        <span class="ant-table-header-column">
                                                            <div>
                                                                <span class="ant-table-column-title">
                                                                    {{
                                                                        $t("backCheck.report.commercialConflict.columnRegCapCur")
                                                                    }}
                                                                </span>
                                                                <span class="ant-table-column-sorter"></span>
                                                            </div>
                                                        </span>
                                                    </th>
                                                    <th class="ant-table-align-center ant-table-row-cell-break-word"
                                                        style="text-align: center;">
                                                        <span class="ant-table-header-column">
                                                            <div>
                                                                <span class="ant-table-column-title">
                                                                    {{
                                                                        $t("backCheck.report.commercialConflict.columnStatusEnterpeise")
                                                                    }}
                                                                </span>
                                                                <span class="ant-table-column-sorter"></span>
                                                            </div>
                                                        </span>
                                                    </th>
                                                    <th class="ant-table-align-center ant-table-row-cell-break-word"
                                                        style="text-align: center;">
                                                        <span class="ant-table-header-column">
                                                            <div>
                                                                <span class="ant-table-column-title">
                                                                    {{
                                                                        $t("backCheck.report.commercialConflict.columnTitle")
                                                                    }}
                                                                </span>
                                                                <span class="ant-table-column-sorter"></span>
                                                            </div>
                                                        </span>
                                                    </th>
                                                </tr>
                                            </thead>

                                            <tbody class="ant-table-tbody">
                                                <tr class="ant-table-row ant-table-row-level-0"
                                                    v-if="!businessManagerDataList || businessManagerDataList.length < 1">
                                                    <td colspan="7">
                                                        <a-empty />
                                                    </td>
                                                </tr>

                                                <tr v-for="(businessManagerItem, index) in businessManagerDataList"
                                                    :key="index" :style="{
                                                        color: `rgb(${businessManagerQuestion[0].fontColorValue.red},${businessManagerQuestion[0].fontColorValue.green},${businessManagerQuestion[0].fontColorValue.blue})`
                                                    }">
                                                    <td class="ant-table-row-cell-break-word"
                                                        style="text-align: center;">
                                                        {{ businessManagerItem.ryname }}
                                                    </td>
                                                    <td class="ant-table-row-cell-break-word"
                                                        style="text-align: center;">
                                                        {{ businessManagerItem.entname }}
                                                    </td>
                                                    <td class="ant-table-row-cell-break-word"
                                                        style="text-align: center;">
                                                        {{ businessManagerItem.regno }}
                                                    </td>
                                                    <td class="ant-table-row-cell-break-word"
                                                        style="text-align: center;">
                                                        {{ businessManagerItem.regcap }}
                                                    </td>
                                                    <td class="ant-table-row-cell-break-word"
                                                        style="text-align: center;">
                                                        {{ businessManagerItem.regcapcur }}
                                                    </td>
                                                    <td class="ant-table-row-cell-break-word"
                                                        style="text-align: center;">
                                                        {{ businessManagerItem.entstatus }}
                                                    </td>
                                                    <td class="ant-table-row-cell-break-word"
                                                        style="text-align: center;">
                                                        {{ businessManagerItem.position }}
                                                    </td>
                                                </tr>

                                                <tr class="ant-table-row ant-table-row-level-0"
                                                    v-if="businessCompletedDateQuestions">
                                                    <td class="ant-table-row-cell-break-word"
                                                        style="text-align: center;">
                                                        <span v-if="$store.getters.isChinese">
                                                            {{ businessCompletedDateQuestions[0].question }}
                                                        </span>

                                                        <span v-else>
                                                            {{ businessCompletedDateQuestions[0].questionMutipleLang }}
                                                        </span>
                                                    </td>

                                                    <td class="ant-table-row-cell-break-word"
                                                        style="text-align: center;" colspan="7">
                                                        <span
                                                            v-if="$store.getters.isChinese && businessCompletedDateQuestions[0].summaryMutipleLang">
                                                            {{ businessCompletedDateQuestions[0].summaryMutipleLang }}
                                                        </span>

                                                        <span v-else>
                                                            {{ businessCompletedDateQuestions[0].summary }}
                                                        </span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!--失信-->
                        <div v-else-if="item.productNo === productTypes.RiskOfCreditInquiry.Id">
                            <div style="display: none;">
                                {{
                                    creditRiskCompletedDateQuestions = item.questions.filter(x => x.question === "核实时间")
                                }}
                                {{creditRiskListQuestions = item.questions.filter(x => x.question === "失信风险检索")}}
                                {{ creditRiskDataList= (creditRiskListQuestions
                                    && creditRiskListQuestions[0]
                                    && creditRiskListQuestions[0].summary
                                    && creditRiskListQuestions[0].summary.startsWith("["))
                                    ? JSON.parse(creditRiskListQuestions[0].summary) :
                                    undefined }}
                            </div>

                            <div class="ant-table ant-table-scroll-position-left ant-table-default ant-table-bordered">
                                <div class="ant-table-content">
                                    <div class="ant-table-body">
                                        <table>
                                            <colgroup>
                                                <col style="width: 100px; min-width: 100px;">
                                                <col style="width: 100px; min-width: 100px;">
                                                <col style="width: 100px; min-width: 100px;">
                                                <col style="width: 100px; min-width: 100px;">
                                                <col style="width: 100px; min-width: 100px;">
                                                <col style="width: 100px; min-width: 100px;">
                                            </colgroup>

                                            <thead class="ant-table-thead">
                                                <tr>
                                                    <th class="ant-table-align-center ant-table-row-cell-break-word"
                                                        style="text-align: center;">
                                                        <span class="ant-table-header-column">
                                                            <div>
                                                                <span class="ant-table-column-title">
                                                                    {{ $t("backCheck.report.creditRisk.caseNumber") }}
                                                                </span>
                                                                <span class="ant-table-column-sorter"></span>
                                                            </div>
                                                        </span>
                                                    </th>
                                                    <th class="ant-table-align-center ant-table-row-cell-break-word"
                                                        style="text-align: center;">
                                                        <span class="ant-table-header-column">
                                                            <div>
                                                                <span class="ant-table-column-title">
                                                                    {{ $t("backCheck.report.creditRisk.province") }}
                                                                </span>
                                                                <span class="ant-table-column-sorter"></span>
                                                            </div>
                                                        </span>
                                                    </th>
                                                    <th class="ant-table-align-center ant-table-row-cell-break-word"
                                                        style="text-align: center;">
                                                        <span class="ant-table-header-column">
                                                            <div>
                                                                <span class="ant-table-column-title">
                                                                    {{ $t("backCheck.report.creditRisk.court") }}
                                                                </span>
                                                                <span class="ant-table-column-sorter"></span>
                                                            </div>
                                                        </span>
                                                    </th>
                                                    <th class="ant-table-align-center ant-table-row-cell-break-word"
                                                        style="text-align: center;">
                                                        <span class="ant-table-header-column">
                                                            <div>
                                                                <span class="ant-table-column-title">
                                                                    {{ $t("backCheck.report.creditRisk.status") }}
                                                                </span>
                                                                <span class="ant-table-column-sorter"></span>
                                                            </div>
                                                        </span>
                                                    </th>
                                                    <th class="ant-table-align-center ant-table-row-cell-break-word"
                                                        style="text-align: center;">
                                                        <span class="ant-table-header-column">
                                                            <div>
                                                                <span class="ant-table-column-title">
                                                                    {{ $t("backCheck.report.creditRisk.reason") }}
                                                                </span>
                                                                <span class="ant-table-column-sorter"></span>
                                                            </div>
                                                        </span>
                                                    </th>
                                                    <th class="ant-table-align-center ant-table-row-cell-break-word"
                                                        style="text-align: center;">
                                                        <span class="ant-table-header-column">
                                                            <div>
                                                                <span class="ant-table-column-title">
                                                                    {{ $t("backCheck.report.creditRisk.releaseDate") }}
                                                                </span>
                                                                <span class="ant-table-column-sorter"></span>
                                                            </div>
                                                        </span>
                                                    </th>
                                                </tr>
                                            </thead>

                                            <tbody class="ant-table-tbody">
                                                <tr class="ant-table-row ant-table-row-level-0"
                                                    v-if="!creditRiskDataList || creditRiskDataList.length < 1">
                                                    <td colspan="6">
                                                        <a-empty />
                                                    </td>
                                                </tr>

                                                <tr v-for="(creditRiskItem, index) in creditRiskDataList" :key="index"
                                                    :style="{
                                                        color: `rgb(${creditRiskListQuestions[0].fontColorValue.red},${creditRiskListQuestions[0].fontColorValue.green},${creditRiskListQuestions[0].fontColorValue.blue})`
                                                    }">
                                                    <td class="ant-table-row-cell-break-word"
                                                        style="text-align: center;">
                                                        {{ creditRiskItem.CaseNum }}
                                                    </td>
                                                    <td class="ant-table-row-cell-break-word"
                                                        style="text-align: center;">
                                                        {{ creditRiskItem.Province }}
                                                    </td>
                                                    <td class="ant-table-row-cell-break-word"
                                                        style="text-align: center;">
                                                        {{ creditRiskItem.CourtName }}
                                                    </td>
                                                    <td class="ant-table-row-cell-break-word"
                                                        style="text-align: center;">
                                                        {{ creditRiskItem.FulfillmentStatus }}
                                                    </td>
                                                    <td class="ant-table-row-cell-break-word"
                                                        style="text-align: center;">
                                                        {{ creditRiskItem.Reason }}
                                                    </td>
                                                    <td class="ant-table-row-cell-break-word"
                                                        style="text-align: center;">
                                                        {{ creditRiskItem.PublishDate }}
                                                    </td>
                                                </tr>

                                                <tr class="ant-table-row ant-table-row-level-0"
                                                    v-if="creditRiskCompletedDateQuestions">
                                                    <td class="ant-table-row-cell-break-word"
                                                        style="text-align: center;">
                                                        <span v-if="$store.getters.isChinese">
                                                            {{ creditRiskCompletedDateQuestions[0].question }}
                                                        </span>

                                                        <span v-else>
                                                            {{ creditRiskCompletedDateQuestions[0].questionMutipleLang
                                                            }}
                                                        </span>
                                                    </td>

                                                    <td class="ant-table-row-cell-break-word"
                                                        style="text-align: center;" colspan="6">
                                                        <span
                                                            v-if="$store.getters.isChinese && creditRiskCompletedDateQuestions[0].summaryMutipleLang">
                                                            {{ creditRiskCompletedDateQuestions[0].summaryMutipleLang }}
                                                        </span>

                                                        <span v-else>
                                                            {{ creditRiskCompletedDateQuestions[0].summary }}
                                                        </span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!--限制消费-->
                        <div v-else-if="item.productNo === productTypes.LimitConsumptionInquiry.Id">
                            <div style="display: none;">
                                {{
                                    limitConsumptionCompletedDateQuestions =
                                    item.questions.filter(x => x.question === "核实时间")
                                }}
                                {{
                                    limitConsumptionListQuestions = item.questions.filter(x => x.question === "限制消费检索")
                                }}
                                {{ limitConsumptionDataList= (limitConsumptionListQuestions
                                    && limitConsumptionListQuestions[0]
                                    && limitConsumptionListQuestions[0].summary
                                    && limitConsumptionListQuestions[0].summary.startsWith("["))
                                    ? JSON.parse(limitConsumptionListQuestions[0].summary) :
                                    undefined }}
                            </div>

                            <div class="ant-table ant-table-scroll-position-left ant-table-default ant-table-bordered">
                                <div class="ant-table-content">
                                    <div class="ant-table-body">
                                        <table>
                                            <colgroup>
                                                <col style="width: 100px; min-width: 100px;">
                                                <col style="width: 100px; min-width: 100px;">
                                                <col style="width: 100px; min-width: 100px;">
                                                <col style="width: 100px; min-width: 100px;">
                                            </colgroup>

                                            <thead class="ant-table-thead">
                                                <tr>
                                                    <th class="ant-table-align-center ant-table-row-cell-break-word"
                                                        style="text-align: center;">
                                                        <span class="ant-table-header-column">
                                                            <div>
                                                                <span class="ant-table-column-title">
                                                                    {{
                                                                        $t("backCheck.report.limitConsumption.caseNumber")
                                                                    }}
                                                                </span>
                                                                <span class="ant-table-column-sorter"></span>
                                                            </div>
                                                        </span>
                                                    </th>
                                                    <th class="ant-table-align-center ant-table-row-cell-break-word"
                                                        style="text-align: center;">
                                                        <span class="ant-table-header-column">
                                                            <div>
                                                                <span class="ant-table-column-title">
                                                                    {{ $t("backCheck.report.limitConsumption.court") }}
                                                                </span>
                                                                <span class="ant-table-column-sorter"></span>
                                                            </div>
                                                        </span>
                                                    </th>
                                                    <th class="ant-table-align-center ant-table-row-cell-break-word"
                                                        style="text-align: center;">
                                                        <span class="ant-table-header-column">
                                                            <div>
                                                                <span class="ant-table-column-title">
                                                                    {{
                                                                        $t("backCheck.report.limitConsumption.filingDate")
                                                                    }}
                                                                </span>
                                                                <span class="ant-table-column-sorter"></span>
                                                            </div>
                                                        </span>
                                                    </th>
                                                    <th class="ant-table-align-center ant-table-row-cell-break-word"
                                                        style="text-align: center;">
                                                        <span class="ant-table-header-column">
                                                            <div>
                                                                <span class="ant-table-column-title">
                                                                    {{
                                                                        $t("backCheck.report.limitConsumption.releaseDate")
                                                                    }}
                                                                </span>
                                                                <span class="ant-table-column-sorter"></span>
                                                            </div>
                                                        </span>
                                                    </th>
                                                </tr>
                                            </thead>

                                            <tbody class="ant-table-tbody">
                                                <tr class="ant-table-row ant-table-row-level-0"
                                                    v-if="!limitConsumptionDataList || limitConsumptionDataList.length < 1">
                                                    <td colspan="6">
                                                        <a-empty />
                                                    </td>
                                                </tr>

                                                <tr v-for="(limitConsumptionItem, index) in limitConsumptionDataList"
                                                    :key="index" :style="{
                                                        color: `rgb(${creditRiskListQuestions[0].fontColorValue.red},${creditRiskListQuestions[0].fontColorValue.green},${creditRiskListQuestions[0].fontColorValue.blue})`
                                                    }">
                                                    <td class="ant-table-row-cell-break-word"
                                                        style="text-align: center;">
                                                        {{ limitConsumptionItem.CaseNumber }}
                                                    </td>
                                                    <td class="ant-table-row-cell-break-word"
                                                        style="text-align: center;">
                                                        {{ limitConsumptionItem.Court }}
                                                    </td>
                                                    <td class="ant-table-row-cell-break-word"
                                                        style="text-align: center;">
                                                        {{ limitConsumptionItem.CaseDate }}
                                                    </td>
                                                    <td class="ant-table-row-cell-break-word"
                                                        style="text-align: center;">
                                                        {{ limitConsumptionItem.PublishDate }}
                                                    </td>
                                                </tr>

                                                <tr class="ant-table-row ant-table-row-level-0"
                                                    v-if="limitConsumptionCompletedDateQuestions">
                                                    <td class="ant-table-row-cell-break-word"
                                                        style="text-align: center;">
                                                        <span v-if="$store.getters.isChinese">
                                                            {{ limitConsumptionCompletedDateQuestions[0].question }}
                                                        </span>

                                                        <span v-else>
                                                            {{
                                                                limitConsumptionCompletedDateQuestions[0].questionMutipleLang
                                                            }}
                                                        </span>
                                                    </td>

                                                    <td class="ant-table-row-cell-break-word"
                                                        style="text-align: center;" colspan="3">
                                                        <span
                                                            v-if="$store.getters.isChinese && limitConsumptionCompletedDateQuestions[0].summaryMutipleLang">
                                                            {{
                                                                limitConsumptionCompletedDateQuestions[0].summaryMutipleLang
                                                            }}
                                                        </span>

                                                        <span v-else>
                                                            {{ limitConsumptionCompletedDateQuestions[0].summary }}
                                                        </span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!--涉诉-->
                        <div v-else-if="item.productNo === productTypes.LitigationInformationInquiry.Id">
                            <div style="display: none;">
                                {{
                                    litigationInformationInquiryCompletedDateQuestions =
                                    item.questions.filter(x => x.question === "核实时间")
                                }}

                                {{litigationInformationInquiryCaseSummaryQuestions =
                                    item.questions.filter(x => x.question === "涉诉案件概览")}}

                                {{ litigationInformationInquiryCaseSummaryData=
                                (litigationInformationInquiryCaseSummaryQuestions
                                    && litigationInformationInquiryCaseSummaryQuestions[0]
                                    && litigationInformationInquiryCaseSummaryQuestions[0].summary
                                    && litigationInformationInquiryCaseSummaryQuestions[0].summary.startsWith("{"))
                                    ? JSON.parse(litigationInformationInquiryCaseSummaryQuestions[0].summary) :
                                    undefined }}


                                {{litigationInformationInquiryRoleSummaryQuestions =
                                    item.questions.filter(x => x.question === "涉诉角色概览")}}
                                {{ litigationInformationInquiryRoleSummaryData=
                                (litigationInformationInquiryRoleSummaryQuestions
                                    && litigationInformationInquiryRoleSummaryQuestions[0]
                                    && litigationInformationInquiryRoleSummaryQuestions[0].summary
                                    && litigationInformationInquiryRoleSummaryQuestions[0].summary.startsWith("{"))
                                    ? JSON.parse(litigationInformationInquiryRoleSummaryQuestions[0].summary) :
                                    undefined }}

                                {{litigationInformationInquiryCivilCaseQuestions =
                                    item.questions.filter(x => x.question === "民事案件检索")}}

                                {{ litigationInformationInquiryCivilCaseDataList =
                                (litigationInformationInquiryCivilCaseQuestions
                                    && litigationInformationInquiryCivilCaseQuestions[0]
                                    && litigationInformationInquiryCivilCaseQuestions[0].summary
                                    && litigationInformationInquiryCivilCaseQuestions[0].summary.startsWith("["))
                                    ? JSON.parse(litigationInformationInquiryCivilCaseQuestions[0].summary) :
                                    undefined }}


                                {{litigationInformationInquiryCriminalCaseQuestions =
                                    item.questions.filter(x => x.question === "刑事案件检索")}}

                                {{ litigationInformationInquiryCriminalCaseDataList =
                                (litigationInformationInquiryCriminalCaseQuestions
                                    && litigationInformationInquiryCriminalCaseQuestions[0]
                                    && litigationInformationInquiryCriminalCaseQuestions[0].summary
                                    && litigationInformationInquiryCriminalCaseQuestions[0].summary.startsWith("["))
                                    ? JSON.parse(litigationInformationInquiryCriminalCaseQuestions[0].summary) :
                                    undefined }}


                                {{litigationInformationInquiryAdministrationCaseQuestions =
                                    item.questions.filter(x => x.question === "行政案件检索")}}

                                {{ litigationInformationInquiryAdministrationCaseDataList =
                                (litigationInformationInquiryAdministrationCaseQuestions
                                    && litigationInformationInquiryAdministrationCaseQuestions[0]
                                    && litigationInformationInquiryAdministrationCaseQuestions[0].summary
                                    && litigationInformationInquiryAdministrationCaseQuestions[0].summary.startsWith("["))
                                    ? JSON.parse(litigationInformationInquiryAdministrationCaseQuestions[0].summary) :
                                    undefined }}


                                {{litigationInformationInquiryExecutionCaseQuestions =
                                    item.questions.filter(x => x.question === "执行案件检索")}}

                                {{ litigationInformationInquiryExecutionCaseDataList =
                                (litigationInformationInquiryExecutionCaseQuestions
                                    && litigationInformationInquiryExecutionCaseQuestions[0]
                                    && litigationInformationInquiryExecutionCaseQuestions[0].summary
                                    && litigationInformationInquiryExecutionCaseQuestions[0].summary.startsWith("["))
                                    ? JSON.parse(litigationInformationInquiryExecutionCaseQuestions[0].summary) :
                                    undefined }}


                                {{litigationInformationInquiryLiquidationBankruptcyQuestions =
                                    item.questions.filter(x => x.question === "强制清算与破产信息检索")}}

                                {{ litigationInformationInquiryLiquidationBankruptcyDataList =
                                (litigationInformationInquiryLiquidationBankruptcyQuestions
                                    && litigationInformationInquiryLiquidationBankruptcyQuestions[0]
                                    && litigationInformationInquiryLiquidationBankruptcyQuestions[0].summary
                                    &&
                                    litigationInformationInquiryLiquidationBankruptcyQuestions[0].summary.startsWith("["))
                                    ? JSON.parse(litigationInformationInquiryLiquidationBankruptcyQuestions[0].summary) :
                                    undefined }}


                                {{litigationInformationInquiryNonLitigationSecurityQuestions =
                                    item.questions.filter(x => x.question === "非诉保全审查")}}

                                {{ litigationInformationInquiryNonLitigationSecurityDataList =
                                (litigationInformationInquiryNonLitigationSecurityQuestions
                                    && litigationInformationInquiryNonLitigationSecurityQuestions[0]
                                    && litigationInformationInquiryNonLitigationSecurityQuestions[0].summary
                                    &&
                                    litigationInformationInquiryNonLitigationSecurityQuestions[0].summary.startsWith("["))
                                    ? JSON.parse(litigationInformationInquiryNonLitigationSecurityQuestions[0].summary) :
                                    undefined }}

                                {{
                                    casesDetailArray = [
                                        {
                                            name: $t("backCheck.report.litigationInformationInquiry.productNameCivilCase"),
                                            fontColorValue: litigationInformationInquiryCivilCaseQuestions[0].fontColorValue,
                                            data: litigationInformationInquiryCivilCaseDataList ?? []
                                        },
                                        {
                                            name: $t("backCheck.report.litigationInformationInquiry.productNameCriminalCase"),
                                            fontColorValue: litigationInformationInquiryCriminalCaseQuestions[0].fontColorValue,
                                            data: litigationInformationInquiryCriminalCaseDataList ?? []
                                        },
                                        {
                                            name: $t("backCheck.report.litigationInformationInquiry.productNameAdministrationCase"),
                                            fontColorValue:
                                                litigationInformationInquiryAdministrationCaseQuestions[0].fontColorValue,
                                            data: litigationInformationInquiryAdministrationCaseDataList ?? []
                                        },
                                        {
                                            name: $t("backCheck.report.litigationInformationInquiry.productNameExecutionCase"),
                                            fontColorValue: litigationInformationInquiryExecutionCaseQuestions[0].fontColorValue,
                                            data: litigationInformationInquiryExecutionCaseDataList ?? []
                                        },
                                        {
                                            name:
                                                $t("backCheck.report.litigationInformationInquiry.productNameLiquidationBankruptcy"),
                                            fontColorValue:
                                                litigationInformationInquiryLiquidationBankruptcyQuestions[0].fontColorValue,
                                            data: litigationInformationInquiryLiquidationBankruptcyDataList ?? []
                                        },
                                        {
                                            name:
                                                $t("backCheck.report.litigationInformationInquiry.productNameNonLitigationSecurity"),
                                            fontColorValue:
                                                litigationInformationInquiryNonLitigationSecurityQuestions[0].fontColorValue,
                                            data: litigationInformationInquiryNonLitigationSecurityDataList ?? []
                                        }
                                    ]
                                }}
                            </div>

                            <div class="ant-table ant-table-scroll-position-left ant-table-default ant-table-bordered">
                                <div class="ant-table-title" style="text-align: center; font-size: 1.1em;">
                                    <b>
                                        {{
                                            $t("backCheck.report.litigationInformationInquiry.tableNameSummaryCases")
                                        }}
                                    </b>
                                </div>

                                <div class="ant-table-content">
                                    <div class="ant-table-body">
                                        <table>
                                            <colgroup>
                                                <col style="width: 100px; min-width: 100px;">
                                                <col style="width: 100px; min-width: 100px;">
                                                <col style="width: 100px; min-width: 100px;">
                                            </colgroup>

                                            <thead class="ant-table-thead">
                                                <tr>
                                                    <th class="ant-table-align-center ant-table-row-cell-break-word"
                                                        style="text-align: center;">
                                                        <span class="ant-table-header-column">
                                                            <div>
                                                                <span class="ant-table-column-title"></span>
                                                                <span class="ant-table-column-sorter"></span>
                                                            </div>
                                                        </span>
                                                    </th>
                                                    <th class="ant-table-align-center ant-table-row-cell-break-word"
                                                        style="text-align: center;">
                                                        <span class="ant-table-header-column">
                                                            <div>
                                                                <span class="ant-table-column-title">
                                                                    {{
                                                                        $t("backCheck.report.litigationInformationInquiry.riskPostive")
                                                                    }}
                                                                </span>
                                                                <span class="ant-table-column-sorter"></span>
                                                            </div>
                                                        </span>
                                                    </th>
                                                    <th class="ant-table-align-center ant-table-row-cell-break-word"
                                                        style="text-align: center;">
                                                        <span class="ant-table-header-column">
                                                            <div>
                                                                <span class="ant-table-column-title">
                                                                    {{
                                                                        $t("backCheck.report.litigationInformationInquiry.riskNegative")
                                                                    }}
                                                                </span>
                                                                <span class="ant-table-column-sorter"></span>
                                                            </div>
                                                        </span>
                                                    </th>
                                                </tr>
                                            </thead>

                                            <tbody class="ant-table-tbody">
                                                <tr>
                                                    <td class="ant-table-row-cell-break-word"
                                                        style="text-align: center;font-weight: bold;">
                                                        {{
                                                            $t("backCheck.report.litigationInformationInquiry.tableNameCivilCase")
                                                        }}
                                                    </td>
                                                    <td class="ant-table-row-cell-break-word"
                                                        style="text-align: center;color: red; font-weight: bold;">
                                                        <span
                                                            v-if="litigationInformationInquiryCaseSummaryData.CivilCase === true">√</span>
                                                    </td>
                                                    <td class="ant-table-row-cell-break-word"
                                                        style="text-align: center;">
                                                        <span
                                                            v-if="litigationInformationInquiryCaseSummaryData.CivilCase !== true">x</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="ant-table-row-cell-break-word"
                                                        style="text-align: center;font-weight: bold;">
                                                        {{
                                                            $t("backCheck.report.litigationInformationInquiry.tableNameCriminalCase")
                                                        }}
                                                    </td>
                                                    <td class="ant-table-row-cell-break-word"
                                                        style="text-align: center;color: red; font-weight: bold;">
                                                        <span
                                                            v-if="litigationInformationInquiryCaseSummaryData.CriminalCase === true">√</span>
                                                    </td>
                                                    <td class="ant-table-row-cell-break-word"
                                                        style="text-align: center;">
                                                        <span
                                                            v-if="litigationInformationInquiryCaseSummaryData.CriminalCase !== true">x</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="ant-table-row-cell-break-word"
                                                        style="text-align: center;font-weight: bold;">
                                                        {{
                                                            $t("backCheck.report.litigationInformationInquiry.tableNameAdministrationCase")
                                                        }}
                                                    </td>
                                                    <td class="ant-table-row-cell-break-word"
                                                        style="text-align: center;color: red; font-weight: bold;">
                                                        <span
                                                            v-if="litigationInformationInquiryCaseSummaryData.AdministrativeCase === true">√</span>
                                                    </td>
                                                    <td class="ant-table-row-cell-break-word"
                                                        style="text-align: center;">
                                                        <span
                                                            v-if="litigationInformationInquiryCaseSummaryData.AdministrativeCase !== true">x</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="ant-table-row-cell-break-word"
                                                        style="text-align: center;font-weight: bold;">
                                                        {{
                                                            $t("backCheck.report.litigationInformationInquiry.tableNameExecutionCase")
                                                        }}
                                                    </td>
                                                    <td class="ant-table-row-cell-break-word"
                                                        style="text-align: center;color: red; font-weight: bold;">
                                                        <span
                                                            v-if="litigationInformationInquiryCaseSummaryData.EnforcementCase === true">√</span>
                                                    </td>
                                                    <td class="ant-table-row-cell-break-word"
                                                        style="text-align: center;">
                                                        <span
                                                            v-if="litigationInformationInquiryCaseSummaryData.EnforcementCase !== true">x</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="ant-table-row-cell-break-word"
                                                        style="text-align: center;font-weight: bold;">
                                                        {{
                                                            $t("backCheck.report.litigationInformationInquiry.tableNameLiquidationBankruptcy")
                                                        }}
                                                    </td>
                                                    <td class="ant-table-row-cell-break-word"
                                                        style="text-align: center;color: red; font-weight: bold;">
                                                        <span
                                                            v-if="litigationInformationInquiryCaseSummaryData.ForcedLiquidationAndBankruptcy === true">√</span>
                                                    </td>
                                                    <td class="ant-table-row-cell-break-word"
                                                        style="text-align: center;">
                                                        <span
                                                            v-if="litigationInformationInquiryCaseSummaryData.ForcedLiquidationAndBankruptcy !== true">x</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="ant-table-row-cell-break-word"
                                                        style="text-align: center;font-weight: bold;">
                                                        {{
                                                            $t("backCheck.report.litigationInformationInquiry.tableNameNonLitigationSecurity")
                                                        }}
                                                    </td>
                                                    <td class="ant-table-row-cell-break-word"
                                                        style="text-align: center;color: red; font-weight: bold;">
                                                        <span
                                                            v-if="litigationInformationInquiryCaseSummaryData.PreservationSearch === true">√</span>
                                                    </td>
                                                    <td class="ant-table-row-cell-break-word"
                                                        style="text-align: center;">
                                                        <span
                                                            v-if="litigationInformationInquiryCaseSummaryData.PreservationSearch !== true">x</span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                            <br>

                            <div class="ant-table ant-table-scroll-position-left ant-table-default ant-table-bordered">
                                <div class="ant-table-title" style="text-align: center; font-size: 1.1em;">
                                    <b>
                                        {{
                                            $t("backCheck.report.litigationInformationInquiry.tableNameSummaryParties")
                                        }}
                                    </b>
                                </div>

                                <div class="ant-table-content">
                                    <div class="ant-table-body">
                                        <table>
                                            <colgroup>
                                                <col style="width: 100px; min-width: 100px;">
                                                <col style="width: 100px; min-width: 100px;">
                                                <col style="width: 100px; min-width: 100px;">
                                                <col style="width: 100px; min-width: 100px;">
                                            </colgroup>

                                            <thead class="ant-table-thead">
                                                <tr>
                                                    <th class="ant-table-align-center ant-table-row-cell-break-word"
                                                        style="text-align: center;">
                                                        <span class="ant-table-header-column">
                                                            <div>
                                                                <span class="ant-table-column-title"></span>
                                                                <span class="ant-table-column-sorter"></span>
                                                            </div>
                                                        </span>
                                                    </th>
                                                    <th class="ant-table-align-center ant-table-row-cell-break-word"
                                                        style="text-align: center;">
                                                        <span class="ant-table-header-column">
                                                            <div>
                                                                <span class="ant-table-column-title">
                                                                    {{
                                                                        $t("backCheck.report.litigationInformationInquiry.partyPlaintiff")
                                                                    }}
                                                                </span>
                                                                <span class="ant-table-column-sorter"></span>
                                                            </div>
                                                        </span>
                                                    </th>
                                                    <th class="ant-table-align-center ant-table-row-cell-break-word"
                                                        style="text-align: center;">
                                                        <span class="ant-table-header-column">
                                                            <div>
                                                                <span class="ant-table-column-title">
                                                                    {{
                                                                        $t("backCheck.report.litigationInformationInquiry.partyDefendant")
                                                                    }}
                                                                </span>
                                                                <span class="ant-table-column-sorter"></span>
                                                            </div>
                                                        </span>
                                                    </th>
                                                    <th class="ant-table-align-center ant-table-row-cell-break-word"
                                                        style="text-align: center;">
                                                        <span class="ant-table-header-column">
                                                            <div>
                                                                <span class="ant-table-column-title">
                                                                    {{
                                                                        $t("backCheck.report.litigationInformationInquiry.partyThird")
                                                                    }}
                                                                </span>
                                                                <span class="ant-table-column-sorter"></span>
                                                            </div>
                                                        </span>
                                                    </th>
                                                </tr>
                                            </thead>

                                            <tbody class="ant-table-tbody">
                                                <tr>
                                                    <td class="ant-table-row-cell-break-word"
                                                        style="text-align: center;font-weight: bold;">
                                                        {{
                                                            $t("backCheck.report.litigationInformationInquiry.riskPostive")
                                                        }}
                                                    </td>
                                                    <td class="ant-table-row-cell-break-word"
                                                        style="text-align: center;color: red; font-weight: bold;">
                                                        <span
                                                            v-if="litigationInformationInquiryRoleSummaryData.Accuser === true">√</span>
                                                    </td>
                                                    <td class="ant-table-row-cell-break-word"
                                                        style="text-align: center;color: red; font-weight: bold;">
                                                        <span
                                                            v-if="litigationInformationInquiryRoleSummaryData.Defendant === true">√</span>
                                                    </td>
                                                    <td class="ant-table-row-cell-break-word"
                                                        style="text-align: center;color: red; font-weight: bold;">
                                                        <span
                                                            v-if="litigationInformationInquiryRoleSummaryData.TheThirdMan === true">√</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="ant-table-row-cell-break-word"
                                                        style="text-align: center;font-weight: bold;">
                                                        {{
                                                            $t("backCheck.report.litigationInformationInquiry.riskNegative")
                                                        }}
                                                    </td>
                                                    <td class="ant-table-row-cell-break-word"
                                                        style="text-align: center;">
                                                        <span
                                                            v-if="litigationInformationInquiryRoleSummaryData.Accuser !== true">x</span>
                                                    </td>
                                                    <td class="ant-table-row-cell-break-word"
                                                        style="text-align: center;">
                                                        <span
                                                            v-if="litigationInformationInquiryRoleSummaryData.Defendant !== true">x</span>
                                                    </td>
                                                    <td class="ant-table-row-cell-break-word"
                                                        style="text-align: center;">
                                                        <span
                                                            v-if="litigationInformationInquiryRoleSummaryData.TheThirdMan !== true">x</span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                            <!--案件详情-->
                            <div v-for="(item, index) in casesDetailArray" :key="index">
                                <br>

                                <div
                                    class="ant-table ant-table-scroll-position-left ant-table-default ant-table-bordered">
                                    <div class="ant-table-title" style="text-align: center; font-size: 1.1em;">
                                        <b>{{ item.name }}</b>
                                    </div>

                                    <div class="ant-table-content">
                                        <div class="ant-table-body">
                                            <table>
                                                <colgroup>
                                                    <col style="width: 100px; min-width: 100px;">
                                                    <col style="width: 100px; min-width: 100px;">
                                                    <col style="width: 100px; min-width: 100px;">
                                                    <col style="width: 100px; min-width: 100px;">
                                                    <col style="width: 100px; min-width: 100px;">
                                                    <col style="width: 100px; min-width: 100px;">
                                                </colgroup>

                                                <thead class="ant-table-thead">
                                                    <tr>
                                                        <th class="ant-table-align-center ant-table-row-cell-break-word"
                                                            style="text-align: center;">
                                                            <span class="ant-table-header-column">
                                                                <div>
                                                                    <span class="ant-table-column-title">
                                                                        {{
                                                                            $t("backCheck.report.litigationInformationInquiry.tableNameYear")
                                                                        }}
                                                                    </span>
                                                                    <span class="ant-table-column-sorter"></span>
                                                                </div>
                                                            </span>
                                                        </th>
                                                        <th class="ant-table-align-center ant-table-row-cell-break-word"
                                                            style="text-align: center;">
                                                            <span class="ant-table-header-column">
                                                                <div>
                                                                    <span class="ant-table-column-title">
                                                                        {{
                                                                            $t("backCheck.report.litigationInformationInquiry.tableNameSummary")
                                                                        }}
                                                                    </span>
                                                                    <span class="ant-table-column-sorter"></span>
                                                                </div>
                                                            </span>
                                                        </th>
                                                        <th class="ant-table-align-center ant-table-row-cell-break-word"
                                                            style="text-align: center;">
                                                            <span class="ant-table-header-column">
                                                                <div>
                                                                    <span class="ant-table-column-title">
                                                                        {{
                                                                            $t("backCheck.report.litigationInformationInquiry.tableNameParty")
                                                                        }}
                                                                    </span>
                                                                    <span class="ant-table-column-sorter"></span>
                                                                </div>
                                                            </span>
                                                        </th>
                                                        <th class="ant-table-align-center ant-table-row-cell-break-word"
                                                            style="text-align: center;">
                                                            <span class="ant-table-header-column">
                                                                <div>
                                                                    <span class="ant-table-column-title">
                                                                        {{
                                                                            $t("backCheck.report.litigationInformationInquiry.tableNameLocation")
                                                                        }}
                                                                    </span>
                                                                    <span class="ant-table-column-sorter"></span>
                                                                </div>
                                                            </span>
                                                        </th>
                                                        <th class="ant-table-align-center ant-table-row-cell-break-word"
                                                            style="text-align: center;">
                                                            <span class="ant-table-header-column">
                                                                <div>
                                                                    <span class="ant-table-column-title">
                                                                        {{
                                                                            $t("backCheck.report.litigationInformationInquiry.tableNameAmount")
                                                                        }}
                                                                    </span>
                                                                    <span class="ant-table-column-sorter"></span>
                                                                </div>
                                                            </span>
                                                        </th>
                                                        <th class="ant-table-align-center ant-table-row-cell-break-word"
                                                            style="text-align: center;">
                                                            <span class="ant-table-header-column">
                                                                <div>
                                                                    <span class="ant-table-column-title">
                                                                        {{
                                                                            $t("backCheck.report.litigationInformationInquiry.tableNameResult")
                                                                        }}
                                                                    </span>
                                                                    <span class="ant-table-column-sorter"></span>
                                                                </div>
                                                            </span>
                                                        </th>
                                                    </tr>
                                                </thead>

                                                <tbody class="ant-table-tbody">
                                                    <tr class="ant-table-row ant-table-row-level-0"
                                                        v-if="!item.data || item.data.length < 1">
                                                        <td colspan="6">
                                                            <a-empty />
                                                        </td>
                                                    </tr>

                                                    <!--数据-->
                                                    <tr v-for="(litigationDetailItem, index) in item.data" :key="index"
                                                        :style="{
                                                            color: `rgb(${item.fontColorValue.red},${item.fontColorValue.green},${item.fontColorValue.blue})`
                                                        }">
                                                        <td class="ant-table-row-cell-break-word"
                                                            style="text-align: center;">
                                                            {{ litigationDetailItem.Year }}
                                                        </td>
                                                        <td class="ant-table-row-cell-break-word"
                                                            style="text-align: center;">
                                                            {{ litigationDetailItem.Reason }}
                                                        </td>
                                                        <td class="ant-table-row-cell-break-word"
                                                            style="text-align: center;">
                                                            {{ litigationDetailItem.Role }}
                                                        </td>
                                                        <td class="ant-table-row-cell-break-word"
                                                            style="text-align: center;">
                                                            {{ litigationDetailItem.Region }}
                                                        </td>
                                                        <td class="ant-table-row-cell-break-word"
                                                            style="text-align: center;">
                                                            {{ litigationDetailItem.MoneySum }}
                                                        </td>
                                                        <td class="ant-table-row-cell-break-word"
                                                            style="text-align: center;">
                                                            {{ litigationDetailItem.CaseCloseResult }}
                                                        </td>
                                                    </tr>

                                                    <tr class="ant-table-row ant-table-row-level-0"
                                                        v-if="litigationInformationInquiryCompletedDateQuestions">
                                                        <td class="ant-table-row-cell-break-word"
                                                            style="text-align: center;">
                                                            <span v-if="$store.getters.isChinese">
                                                                {{
                                                                    litigationInformationInquiryCompletedDateQuestions[0].question
                                                                }}
                                                            </span>

                                                            <span v-else>
                                                                {{
                                                                    litigationInformationInquiryCompletedDateQuestions[0].questionMutipleLang
                                                                }}
                                                            </span>
                                                        </td>

                                                        <td class="ant-table-row-cell-break-word"
                                                            style="text-align: center;" colspan="6">
                                                            <span
                                                                v-if="$store.getters.isChinese && litigationInformationInquiryCompletedDateQuestions[0].summaryMutipleLang">
                                                                {{
                                                                    litigationInformationInquiryCompletedDateQuestions[0].summaryMutipleLang
                                                                }}
                                                            </span>

                                                            <span v-else>
                                                                {{
                                                                    litigationInformationInquiryCompletedDateQuestions[0].summary
                                                                }}
                                                            </span>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!--逾期-->
                        <div v-else-if="item.productNo === productTypes.OverdueRecordEnquiry.Id">
                            <div style="display: none;">
                                {{
                                    overdueCompletedDateQuestions = item.questions.filter(x => x.question === "核实时间")
                                }}

                                {{overdueQuestions = item.questions.filter(x => x.question === "逾期记录检索")}}

                                {{ overdueData =
                                (overdueQuestions
                                    && overdueQuestions[0]
                                    && overdueQuestions[0].summary
                                    && overdueQuestions[0].summary.startsWith("{"))
                                    ? JSON.parse(overdueQuestions[0].summary) :
                                    undefined }}
                            </div>

                            <div class="ant-table ant-table-scroll-position-left ant-table-default ant-table-bordered">
                                <div class="ant-table-content">
                                    <div class="ant-table-body">
                                        <table>
                                            <colgroup>
                                                <col style="width: 24%">
                                                <col style="width: 19%">
                                                <col style="width: 19%">
                                                <col style="width: 19%">
                                                <col style="width: 19%">
                                            </colgroup>

                                            <tbody class="ant-table-tbody"
                                                v-if="!overdueData || overdueData.length < 1">
                                                <tr class="ant-table-row ant-table-row-level-0">
                                                    <td colspan="5">
                                                        <a-empty />
                                                    </td>
                                                </tr>
                                            </tbody>

                                            <tbody class="ant-table-tbody">
                                                <tr>
                                                    <td class="ant-table-row-cell-break-word"
                                                        style="text-align: center; font-weight: bold;" rowspan="2">
                                                        {{
                                                            $t("backCheck.report.overdue.tableColumnTotal")
                                                        }}
                                                    </td>
                                                    <td class="ant-table-row-cell-break-word"
                                                        style="text-align: center; font-weight: bold;">
                                                        {{
                                                            $t("backCheck.report.overdue.tableColumnClass1")
                                                        }}
                                                    </td>
                                                    <td class="ant-table-row-cell-break-word"
                                                        style="text-align: center; font-weight: bold;">
                                                        {{
                                                            $t("backCheck.report.overdue.tableColumnClass2")
                                                        }}
                                                    </td>
                                                    <td class="ant-table-row-cell-break-word"
                                                        style="text-align: center; font-weight: bold;">
                                                        {{
                                                            $t("backCheck.report.overdue.tableColumnClass3")
                                                        }}
                                                    </td>
                                                    <td class="ant-table-row-cell-break-word"
                                                        style="text-align: center; font-weight: bold;">
                                                        {{
                                                            $t("backCheck.report.overdue.tableColumnClass4")
                                                        }}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="ant-table-row-cell-break-word" :style="{
                                                        textAlign: 'center',
                                                        fontWeight: 'bold',
                                                        color: `rgb(${overdueQuestions[0].fontColorValue.red},${overdueQuestions[0].fontColorValue.green},${overdueQuestions[0].fontColorValue.blue})`
                                                    }">
                                                        {{ overdueData.PastTwoYearTimesClassFirst }}
                                                    </td>
                                                    <td class="ant-table-row-cell-break-word" :style="{
                                                        textAlign: 'center',
                                                        fontWeight: 'bold',
                                                        color: `rgb(${overdueQuestions[0].fontColorValue.red},${overdueQuestions[0].fontColorValue.green},${overdueQuestions[0].fontColorValue.blue})`
                                                    }">
                                                        {{ overdueData.PastTwoYearTimesClassSecond }}
                                                    </td>
                                                    <td class="ant-table-row-cell-break-word" :style="{
                                                        textAlign: 'center',
                                                        fontWeight: 'bold',
                                                        color: `rgb(${overdueQuestions[0].fontColorValue.red},${overdueQuestions[0].fontColorValue.green},${overdueQuestions[0].fontColorValue.blue})`
                                                    }">
                                                        {{ overdueData.PastTwoYearTimesClassThird }}
                                                    </td>
                                                    <td class="ant-table-row-cell-break-word" :style="{
                                                        textAlign: 'center',
                                                        fontWeight: 'bold',
                                                        color: `rgb(${overdueQuestions[0].fontColorValue.red},${overdueQuestions[0].fontColorValue.green},${overdueQuestions[0].fontColorValue.blue})`
                                                    }">
                                                        {{ overdueData.PastTwoYearTimesClassForth }}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="ant-table-row-cell-break-word"
                                                        style="text-align: center; font-weight: bold;">
                                                        {{
                                                            $t("backCheck.report.overdue.tableColumnLatestYear")
                                                        }}
                                                    </td>
                                                    <td class="ant-table-row-cell-break-word" colspan="4" :style="{
                                                        textAlign: 'center',
                                                        fontWeight: 'bold',
                                                        color: `rgb(${overdueQuestions[0].fontColorValue.red},${overdueQuestions[0].fontColorValue.green},${overdueQuestions[0].fontColorValue.blue})`
                                                    }">
                                                        {{ overdueData.LatestYear }}
                                                    </td>
                                                </tr>
                                            </tbody>

                                            <tbody class="ant-table-tbody">
                                                <tr class="ant-table-row ant-table-row-level-0"
                                                    v-if="overdueCompletedDateQuestions">
                                                    <td class="ant-table-row-cell-break-word"
                                                        style="text-align: center;">
                                                        <span v-if="$store.getters.isChinese">
                                                            {{ overdueCompletedDateQuestions[0].question }}
                                                        </span>

                                                        <span v-else>
                                                            {{ overdueCompletedDateQuestions[0].questionMutipleLang }}
                                                        </span>
                                                    </td>

                                                    <td class="ant-table-row-cell-break-word"
                                                        style="text-align: center;" colspan="4">
                                                        <span
                                                            v-if="$store.getters.isChinese && overdueCompletedDateQuestions[0].summaryMutipleLang">
                                                            {{ overdueCompletedDateQuestions[0].summaryMutipleLang }}
                                                        </span>

                                                        <span v-else>
                                                            {{ overdueCompletedDateQuestions[0].summary }}
                                                        </span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!--逾期(概述)-->
                        <div v-else-if="item.productNo === productTypes.OverdueRecordEnquirySummary.Id">
                            <div style="display: none;">
                                {{
                                    overdueCompletedDateQuestions = item.questions.filter(x => x.question === "核实时间")
                                }}

                                {{overdueQuestions = item.questions.filter(x => x.question === "逾期记录检索")}}

                                {{ overdueData =
                                (overdueQuestions
                                    && overdueQuestions[0]
                                    && overdueQuestions[0].summary
                                    && overdueQuestions[0].summary.startsWith("{"))
                                    ? JSON.parse(overdueQuestions[0].summary) :
                                    undefined }}
                            </div>

                            <div class="ant-table ant-table-scroll-position-left ant-table-default ant-table-bordered">
                                <div class="ant-table-content">
                                    <div class="ant-table-body">
                                        <table>
                                            <colgroup>
                                                <col style="width: 24%">
                                                <col style="width: 19%">
                                                <col style="width: 19%">
                                                <col style="width: 19%">
                                                <col style="width: 19%">
                                            </colgroup>

                                            <tbody class="ant-table-tbody"
                                                v-if="!overdueData || overdueData.length < 1">
                                                <tr class="ant-table-row ant-table-row-level-0">
                                                    <td colspan="5">
                                                        <a-empty />
                                                    </td>
                                                </tr>
                                            </tbody>

                                            <tbody class="ant-table-tbody">
                                                <tr>
                                                    <td class="ant-table-row-cell-break-word"
                                                        style="text-align: center; font-weight: bold;">
                                                        {{
                                                            $t("backCheck.report.overdue.tableColumnTotal")
                                                        }}
                                                    </td>
                                                    <td class="ant-table-row-cell-break-word" colspan="4" :style="{
                                                        textAlign: 'center',
                                                        fontWeight: 'bold',
                                                        color: `rgb(${overdueQuestions[0].fontColorValue.red},${overdueQuestions[0].fontColorValue.green},${overdueQuestions[0].fontColorValue.blue})`
                                                    }">
                                                        {{ overdueData.PastTwoYearTimesTotal }}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="ant-table-row-cell-break-word"
                                                        style="text-align: center; font-weight: bold;">
                                                        {{
                                                            $t("backCheck.report.overdue.tableColumnLatestYear")
                                                        }}
                                                    </td>
                                                    <td class="ant-table-row-cell-break-word" colspan="4" :style="{
                                                        textAlign: 'center',
                                                        fontWeight: 'bold',
                                                        color: `rgb(${overdueQuestions[0].fontColorValue.red},${overdueQuestions[0].fontColorValue.green},${overdueQuestions[0].fontColorValue.blue})`
                                                    }">
                                                        {{ overdueData.LatestYear }}
                                                    </td>
                                                </tr>
                                            </tbody>

                                            <tbody class="ant-table-tbody">
                                                <tr class="ant-table-row ant-table-row-level-0"
                                                    v-if="overdueCompletedDateQuestions">
                                                    <td class="ant-table-row-cell-break-word"
                                                        style="text-align: center;">
                                                        <span v-if="$store.getters.isChinese">
                                                            {{ overdueCompletedDateQuestions[0].question }}
                                                        </span>

                                                        <span v-else>
                                                            {{ overdueCompletedDateQuestions[0].questionMutipleLang }}
                                                        </span>
                                                    </td>

                                                    <td class="ant-table-row-cell-break-word"
                                                        style="text-align: center;" colspan="4">
                                                        <span
                                                            v-if="$store.getters.isChinese && overdueCompletedDateQuestions[0].summaryMutipleLang">
                                                            {{ overdueCompletedDateQuestions[0].summaryMutipleLang }}
                                                        </span>

                                                        <span v-else>
                                                            {{ overdueCompletedDateQuestions[0].summary }}
                                                        </span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!--金融违规-->
                        <div v-else-if="item.productNo === productTypes.FinancialViolationRecordsEnquiry.Id">
                            <div style="display: none;">
                                {{
                                    financialViolationCompletedDateQuestions =
                                    item.questions.filter(x => x.question === "核实时间")
                                }}
                                {{
                                    financialViolationListQuestions =
                                    item.questions.filter(x => x.question === "金融违规记录查询")
                                }}
                                {{ financialViolationDataList= (financialViolationListQuestions
                                    && financialViolationListQuestions[0]
                                    && financialViolationListQuestions[0].summary
                                    && financialViolationListQuestions[0].summary.startsWith("["))
                                    ? JSON.parse(financialViolationListQuestions[0].summary) :
                                    undefined }}
                            </div>

                            <div class="ant-table ant-table-scroll-position-left ant-table-default ant-table-bordered">
                                <div class="ant-table-content">
                                    <div class="ant-table-body">
                                        <table>
                                            <colgroup>
                                                <col style="width: 20%;">
                                                <col style="width: 80%;">
                                            </colgroup>

                                            <!--无数据-->
                                            <tbody class="ant-table-tbody"
                                                v-if="!financialViolationDataList || financialViolationDataList.length < 1">
                                                <tr class="ant-table-row ant-table-row-level-0">
                                                    <td colspan="2">
                                                        <a-empty />
                                                    </td>
                                                </tr>
                                            </tbody>

                                            <!--数据-->
                                            <tbody class="ant-table-tbody"
                                                v-for="(financialViolationItem, index) in financialViolationDataList"
                                                :key="index">
                                                <tr>
                                                    <td class="ant-table-row-cell-break-word"
                                                        style="text-align: center; font-weight: bold; font-size: 1.5em;"
                                                        colspan="2">
                                                        {{
                                                            $t("backCheck.report.financialViolation.tableColumnIndex", {
                                                                index: index + 1
                                                            })
                                                        }}
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td class="ant-table-row-cell-break-word"
                                                        style="text-align: center; font-weight: bold;">
                                                        {{
                                                            $t("backCheck.report.financialViolation.tableColumnDocumentNumber")
                                                        }}
                                                    </td>
                                                    <td class="ant-table-row-cell-break-word" :style="{
                                                        textAlign: 'center',
                                                        color: `rgb(${financialViolationListQuestions[0].fontColorValue.red},${financialViolationListQuestions[0].fontColorValue.green},${financialViolationListQuestions[0].fontColorValue.blue})`
                                                    }">
                                                        {{ financialViolationItem.AdministrativePenaltyDecisionNumber }}
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td class="ant-table-row-cell-break-word"
                                                        style="text-align: center; font-weight: bold;">
                                                        {{
                                                            $t("backCheck.report.financialViolation.tableColumnOrganizationPunishment")
                                                        }}
                                                    </td>
                                                    <td class="ant-table-row-cell-break-word" :style="{
                                                        textAlign: 'center',
                                                        color: `rgb(${financialViolationListQuestions[0].fontColorValue.red},${financialViolationListQuestions[0].fontColorValue.green},${financialViolationListQuestions[0].fontColorValue.blue})`
                                                    }">
                                                        {{ financialViolationItem.AdministrativePenaltyDecisionAgency }}
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td class="ant-table-row-cell-break-word"
                                                        style="text-align: center; font-weight: bold;">
                                                        {{
                                                            $t("backCheck.report.financialViolation.tableColumnEnterprise")
                                                        }}
                                                    </td>
                                                    <td class="ant-table-row-cell-break-word" :style="{
                                                        textAlign: 'center',
                                                        color: `rgb(${financialViolationListQuestions[0].fontColorValue.red},${financialViolationListQuestions[0].fontColorValue.green},${financialViolationListQuestions[0].fontColorValue.blue})`
                                                    }">
                                                        {{ financialViolationItem.CompanyName }}
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td class="ant-table-row-cell-break-word"
                                                        style="text-align: center; font-weight: bold;">
                                                        {{
                                                            $t("backCheck.report.financialViolation.tableColumnLegalRepresentative")
                                                        }}
                                                    </td>
                                                    <td class="ant-table-row-cell-break-word" :style="{
                                                        textAlign: 'center',
                                                        color: `rgb(${financialViolationListQuestions[0].fontColorValue.red},${financialViolationListQuestions[0].fontColorValue.green},${financialViolationListQuestions[0].fontColorValue.blue})`
                                                    }">
                                                        {{ financialViolationItem.LegalPerson }}
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td class="ant-table-row-cell-break-word"
                                                        style="text-align: center; font-weight: bold;">
                                                        {{
                                                            $t("backCheck.report.financialViolation.tableColumnName")
                                                        }}
                                                    </td>
                                                    <td class="ant-table-row-cell-break-word" :style="{
                                                        textAlign: 'center',
                                                        color: `rgb(${financialViolationListQuestions[0].fontColorValue.red},${financialViolationListQuestions[0].fontColorValue.green},${financialViolationListQuestions[0].fontColorValue.blue})`
                                                    }">
                                                        {{ financialViolationItem.PartyName }}
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td class="ant-table-row-cell-break-word"
                                                        style="text-align: center; font-weight: bold;">
                                                        {{
                                                            $t("backCheck.report.financialViolation.tableColumnParty")
                                                        }}
                                                    </td>
                                                    <td class="ant-table-row-cell-break-word" :style="{
                                                        textAlign: 'center',
                                                        color: `rgb(${financialViolationListQuestions[0].fontColorValue.red},${financialViolationListQuestions[0].fontColorValue.green},${financialViolationListQuestions[0].fontColorValue.blue})`
                                                    }">
                                                        {{ financialViolationItem.PartyType }}
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td class="ant-table-row-cell-break-word"
                                                        style="text-align: center; font-weight: bold;">
                                                        {{
                                                            $t("backCheck.report.financialViolation.tableColumnGender")
                                                        }}
                                                    </td>
                                                    <td class="ant-table-row-cell-break-word" :style="{
                                                        textAlign: 'center',
                                                        color: `rgb(${financialViolationListQuestions[0].fontColorValue.red},${financialViolationListQuestions[0].fontColorValue.green},${financialViolationListQuestions[0].fontColorValue.blue})`
                                                    }">
                                                        {{ financialViolationItem.PartyGender }}
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td class="ant-table-row-cell-break-word"
                                                        style="text-align: center; font-weight: bold;">
                                                        {{
                                                            $t("backCheck.report.financialViolation.tableColumnBirthday")
                                                        }}
                                                    </td>
                                                    <td class="ant-table-row-cell-break-word" :style="{
                                                        textAlign: 'center',
                                                        color: `rgb(${financialViolationListQuestions[0].fontColorValue.red},${financialViolationListQuestions[0].fontColorValue.green},${financialViolationListQuestions[0].fontColorValue.blue})`
                                                    }">
                                                        {{ financialViolationItem.PartyBirthday }}
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td class="ant-table-row-cell-break-word"
                                                        style="text-align: center; font-weight: bold;">
                                                        {{
                                                            $t("backCheck.report.financialViolation.tableColumnAddress")
                                                        }}
                                                    </td>
                                                    <td class="ant-table-row-cell-break-word" :style="{
                                                        textAlign: 'center',
                                                        color: `rgb(${financialViolationListQuestions[0].fontColorValue.red},${financialViolationListQuestions[0].fontColorValue.green},${financialViolationListQuestions[0].fontColorValue.blue})`
                                                    }">
                                                        {{ financialViolationItem.PartyAddress }}
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td class="ant-table-row-cell-break-word"
                                                        style="text-align: center; font-weight: bold;">
                                                        {{
                                                            $t("backCheck.report.financialViolation.tableColumnTitle")
                                                        }}
                                                    </td>
                                                    <td class="ant-table-row-cell-break-word" :style="{
                                                        textAlign: 'center',
                                                        color: `rgb(${financialViolationListQuestions[0].fontColorValue.red},${financialViolationListQuestions[0].fontColorValue.green},${financialViolationListQuestions[0].fontColorValue.blue})`
                                                    }">
                                                        {{ financialViolationItem.PartyPosition }}
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td class="ant-table-row-cell-break-word"
                                                        style="text-align: center; font-weight: bold;">
                                                        {{
                                                            $t("backCheck.report.financialViolation.tableColumnRegistrationNumber")
                                                        }}
                                                    </td>
                                                    <td class="ant-table-row-cell-break-word" :style="{
                                                        textAlign: 'center',
                                                        color: `rgb(${financialViolationListQuestions[0].fontColorValue.red},${financialViolationListQuestions[0].fontColorValue.green},${financialViolationListQuestions[0].fontColorValue.blue})`
                                                    }">
                                                        {{ financialViolationItem.RegNo }}
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td class="ant-table-row-cell-break-word"
                                                        style="text-align: center; font-weight: bold;">
                                                        {{
                                                            $t("backCheck.report.financialViolation.tableColumnFormerName")
                                                        }}
                                                    </td>
                                                    <td class="ant-table-row-cell-break-word" :style="{
                                                        textAlign: 'center',
                                                        color: `rgb(${financialViolationListQuestions[0].fontColorValue.red},${financialViolationListQuestions[0].fontColorValue.green},${financialViolationListQuestions[0].fontColorValue.blue})`
                                                    }">
                                                        {{ financialViolationItem.PartyNameHistory }}
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td class="ant-table-row-cell-break-word"
                                                        style="text-align: center; font-weight: bold;">
                                                        {{
                                                            $t("backCheck.report.financialViolation.tableColumnPunishmentType")
                                                        }}
                                                    </td>
                                                    <td class="ant-table-row-cell-break-word" :style="{
                                                        textAlign: 'center',
                                                        color: `rgb(${financialViolationListQuestions[0].fontColorValue.red},${financialViolationListQuestions[0].fontColorValue.green},${financialViolationListQuestions[0].fontColorValue.blue})`
                                                    }">
                                                        {{ financialViolationItem.PunishType }}
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td class="ant-table-row-cell-break-word"
                                                        style="text-align: center; font-weight: bold;">
                                                        {{
                                                            $t("backCheck.report.financialViolation.tableColumnPunishmentBasis")
                                                        }}
                                                    </td>
                                                    <td class="ant-table-row-cell-break-word" :style="{
                                                        textAlign: 'center',
                                                        color: `rgb(${financialViolationListQuestions[0].fontColorValue.red},${financialViolationListQuestions[0].fontColorValue.green},${financialViolationListQuestions[0].fontColorValue.blue})`
                                                    }">
                                                        {{ financialViolationItem.PunishBasis }}
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td class="ant-table-row-cell-break-word"
                                                        style="text-align: center; font-weight: bold;">
                                                        {{
                                                            $t("backCheck.report.financialViolation.tableColumnPunishmentContent")
                                                        }}
                                                    </td>
                                                    <td class="ant-table-row-cell-break-word" :style="{
                                                        textAlign: 'center',
                                                        color: `rgb(${financialViolationListQuestions[0].fontColorValue.red},${financialViolationListQuestions[0].fontColorValue.green},${financialViolationListQuestions[0].fontColorValue.blue})`
                                                    }">
                                                        {{ financialViolationItem.PunishContent }}
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td class="ant-table-row-cell-break-word"
                                                        style="text-align: center; font-weight: bold;">
                                                        {{
                                                            $t("backCheck.report.financialViolation.tableColumnIllegalFact")
                                                        }}
                                                    </td>
                                                    <td class="ant-table-row-cell-break-word" :style="{
                                                        textAlign: 'center',
                                                        color: `rgb(${financialViolationListQuestions[0].fontColorValue.red},${financialViolationListQuestions[0].fontColorValue.green},${financialViolationListQuestions[0].fontColorValue.blue})`
                                                    }">
                                                        {{ financialViolationItem.IllegalFacts }}
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td class="ant-table-row-cell-break-word"
                                                        style="text-align: center; font-weight: bold;">
                                                        {{
                                                            $t("backCheck.report.financialViolation.tableColumnDocumentDate")
                                                        }}
                                                    </td>
                                                    <td class="ant-table-row-cell-break-word" :style="{
                                                        textAlign: 'center',
                                                        color: `rgb(${financialViolationListQuestions[0].fontColorValue.red},${financialViolationListQuestions[0].fontColorValue.green},${financialViolationListQuestions[0].fontColorValue.blue})`
                                                    }">
                                                        {{ financialViolationItem.DocumentDate }}
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td class="ant-table-row-cell-break-word"
                                                        style="text-align: center; font-weight: bold;">
                                                        {{
                                                            $t("backCheck.report.financialViolation.tableColumnOrganizationPublish")
                                                        }}
                                                    </td>
                                                    <td class="ant-table-row-cell-break-word" :style="{
                                                        textAlign: 'center',
                                                        color: `rgb(${financialViolationListQuestions[0].fontColorValue.red},${financialViolationListQuestions[0].fontColorValue.green},${financialViolationListQuestions[0].fontColorValue.blue})`
                                                    }">
                                                        {{ financialViolationItem.PublishingAgency }}
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td class="ant-table-row-cell-break-word"
                                                        style="text-align: center; font-weight: bold;">
                                                        {{
                                                            $t("backCheck.report.financialViolation.tableColumnLocation")
                                                        }}
                                                    </td>
                                                    <td class="ant-table-row-cell-break-word" :style="{
                                                        textAlign: 'center',
                                                        color: `rgb(${financialViolationListQuestions[0].fontColorValue.red},${financialViolationListQuestions[0].fontColorValue.green},${financialViolationListQuestions[0].fontColorValue.blue})`
                                                    }">
                                                        {{ financialViolationItem.AreaName }}
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td class="ant-table-row-cell-break-word"
                                                        style="text-align: center; font-weight: bold;">
                                                        {{
                                                            $t("backCheck.report.financialViolation.tableColumnMatchedDegree")
                                                        }}
                                                    </td>
                                                    <td class="ant-table-row-cell-break-word" :style="{
                                                        textAlign: 'center',
                                                        color: `rgb(${financialViolationListQuestions[0].fontColorValue.red},${financialViolationListQuestions[0].fontColorValue.green},${financialViolationListQuestions[0].fontColorValue.blue})`
                                                    }">
                                                        {{ financialViolationItem.MatchRatio }}
                                                    </td>
                                                </tr>
                                            </tbody>

                                            <!--核实时间-->
                                            <tbody class="ant-table-tbody">
                                                <tr class="ant-table-row ant-table-row-level-0"
                                                    v-if="financialViolationCompletedDateQuestions">
                                                    <td class="ant-table-row-cell-break-word"
                                                        style="text-align: center;">
                                                        <span v-if="$store.getters.isChinese">
                                                            {{ financialViolationCompletedDateQuestions[0].question }}
                                                        </span>

                                                        <span v-else>
                                                            {{
                                                                financialViolationCompletedDateQuestions[0].questionMutipleLang
                                                            }}
                                                        </span>
                                                    </td>

                                                    <td class="ant-table-row-cell-break-word"
                                                        style="text-align: center;" colspan="2">
                                                        <span
                                                            v-if="$store.getters.isChinese && financialViolationCompletedDateQuestions[0].summaryMutipleLang">
                                                            {{
                                                                financialViolationCompletedDateQuestions[0].summaryMutipleLang
                                                            }}
                                                        </span>

                                                        <span v-else>
                                                            {{ financialViolationCompletedDateQuestions[0].summary }}
                                                        </span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!--其它不需要特殊处理的模块-->
                        <div class="ant-table ant-table-scroll-position-left ant-table-default ant-table-bordered"
                            v-else>
                            <div class="ant-table-content">
                                <div class="ant-table-body">
                                    <table>
                                        <colgroup>
                                            <col style="width: 50px; min-width: 50px;">
                                            <col style="width: 100px; min-width: 100px;">
                                        </colgroup>

                                        <thead class="ant-table-thead">
                                            <tr>
                                                <th class="ant-table-align-center ant-table-row-cell-break-word"
                                                    style="text-align: center;">
                                                    <span class="ant-table-header-column">
                                                        <div>
                                                            <span class="ant-table-column-title">
                                                                {{ $t("backCheck.report.problemName") }}
                                                            </span>
                                                            <span class="ant-table-column-sorter"></span>
                                                        </div>
                                                    </span>
                                                </th>
                                                <th class="ant-table-align-center ant-table-row-cell-break-word"
                                                    style="text-align: center;">
                                                    <span class="ant-table-header-column">
                                                        <div>
                                                            <span class="ant-table-column-title">
                                                                {{ $t("backCheck.report.summaryValue") }}
                                                            </span>
                                                            <span class="ant-table-column-sorter"></span>
                                                        </div>
                                                    </span>
                                                </th>
                                            </tr>
                                        </thead>

                                        <tbody class="ant-table-tbody">
                                            <tr class="ant-table-row ant-table-row-level-0"
                                                v-for="(questionItem, index) in item.questions" :key="index">
                                                <td class="ant-table-row-cell-break-word" style="text-align: center;">
                                                    <span v-if="$store.getters.isChinese">
                                                        {{ questionItem.question }}
                                                    </span>

                                                    <span v-else>
                                                        {{ questionItem.questionMutipleLang }}
                                                    </span>
                                                </td>

                                                <td class="ant-table-row-cell-break-word" style="text-align: center;"
                                                    :rowspan="questionItem.summaryRowSpan">
                                                    <span :style="{
                                                        color: `rgb(${questionItem.fontColorValue.red},${questionItem.fontColorValue.green},${questionItem.fontColorValue.blue})`
                                                    }">
                                                        <span
                                                            v-if="$store.getters.isChinese && questionItem.summaryMutipleLang">
                                                            {{ questionItem.summaryMutipleLang }}
                                                        </span>

                                                        <span v-else>
                                                            {{ questionItem.summary }}
                                                        </span>
                                                    </span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </a-spin>
        </div>
        <div v-else style="display: flex; justify-content: center;">
            <div class="not-found">
                <div class="not-found-img"></div>
                <div class="not-found-title"
                    style="font-weight: bold;  font-size: 32px;  margin-top: 40px;  margin-bottom: 20px;">{{
                        errorMessage }}</div>
                <div class="not-found-btn" style="margin-top: 40px">
                    <a-button type="primary" ghost block @click="goBack(1)">返回</a-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { FETCH_CODE } from "@/config";
import { showMsg } from "@/utils";
import {
    getFakeId,
} from "@/utils/tools";
import { getReportContent } from '../api';
import { RC_ORDER_PRODUCT_TYPE } from '@/pages/home/config';

export default {
    name: "RcReport",
    data() {
        return {
            reportPublished: true,
            errorMessage: "",
            loading: false,
            reportContent: {},
            reportSummaryTableData: [],
            reportSummaryTableColumn: [
                {
                    title: this.$t("order.report.table.name"),
                    dataIndex: "productName",
                    key: "productName",
                    width: 100,
                    align: "center",
                    scopedSlots: { customRender: "productName" },
                },
                {
                    title: this.$t("order.report.table.status"),
                    dataIndex: "summaryStateText",
                    key: "summaryStateText",
                    width: 50,
                    align: "center",
                    scopedSlots: { customRender: "summaryStateText" },
                },
                {
                    title: this.$t("order.report.table.desc"),
                    dataIndex: "summaryValue",
                    key: "summaryValue",
                    width: 100,
                    align: "center",
                    scopedSlots: { customRender: "summaryValue" },
                },
                {
                    title: this.$t("order.report.table.dp"),
                    dataIndex: "shieldImg",
                    key: "shieldImg",
                    width: 50,
                    align: "center",
                    scopedSlots: { customRender: "shieldImg" },
                },
            ],
            shieldImages: {},
            productTypes: RC_ORDER_PRODUCT_TYPE,
            reportModule: []
        }
    },
    methods: {
        goBack(type = 0) {
            if (type) {
                this.$router.go(-1);
            } else {
                this.$router.replace("/");
            }
        },

        async collectImage() {
            if (!this.reportContent) {
                return;
            }

            //报告等级图片
            if (this.reportContent.reportLevel) {
                const reportImageData = await import(`@/assets/images/dp/${this.reportContent.reportLevel}`);

                if (reportImageData && reportImageData.default) {
                    this.shieldImages[this.reportContent.reportLevel] = reportImageData.default;
                }
            }

            //模块等级图片
            if (this.reportContent.summary) {
                await this.reportContent.summary.forEach(async element => {
                    if (element.shieldImg) {
                        const moduleImageData = await import(`@/assets/images/dp/${element.shieldImg}`);

                        if (moduleImageData && moduleImageData.default) {
                            this.shieldImages[element.shieldImg] = moduleImageData.default;
                        }
                    }
                });
            }
        },

        async collectSummary() {
            if (!this.reportContent) {
                return;
            }

            this.reportSummaryTableData = await this.reportContent.summary
                .filter(item => ![this.productTypes.JobPerformance_SubjectiveAssessment.Id, this.productTypes.JobPerformance_BehaviorCharacteristics.Id, this.productTypes.JobPerformance_Decision.Id].includes(item.productNo))
                .map(item => {
                    const { questions, ...newItem } = item;

                    return {
                        id: getFakeId(),
                        ...newItem
                    };
                });
        },

        async collectContent() {
            if (!this.reportContent) {
                return;
            }

            this.reportModule = await this.reportContent.summary
                .filter(item => ![this.productTypes.JobPerformance_SubjectiveAssessment.Id, this.productTypes.JobPerformance_BehaviorCharacteristics.Id, this.productTypes.JobPerformance_Decision.Id].includes(item.productNo));
        },

        async getReport() {
            this.loading = true;
            const { id } = this.$route.params;

            if (!id || isNaN(id)) {
                this.errorMessage = this.$t("order.detail.invalid");
                this.reportPublished = false;

                await showMsg({
                    flag: FETCH_CODE.ERROR.KEY,
                    msg: this.$t("order.detail.invalid"),
                });
                this.loading = false;
                return;
            }

            const taskNo = Number.parseInt(id);

            if (taskNo < 1) {
                this.errorMessage = this.$t("order.detail.invalid");
                this.reportPublished = false;

                await showMsg({
                    flag: FETCH_CODE.ERROR.KEY,
                    msg: this.$t("order.detail.invalid"),
                });
                this.loading = false;
                return;
            }

            const response = await getReportContent(taskNo);

            if (!response.dataOriginal && !response.data.success) {
                this.errorMessage = response.data.msg;
                this.reportPublished = false;

                await showMsg({
                    flag: FETCH_CODE.ERROR.KEY,
                    msg: response.data.msg,
                });
                this.loading = false;
                return;
            }

            if (!response.data) {
                this.errorMessage = this.$t("order.detail.invalid");
                this.reportPublished = false;

                await showMsg({
                    flag: FETCH_CODE.ERROR.KEY,
                    msg: this.$t("order.detail.invalid"),
                });
                this.loading = false;
                return;
            }

            this.reportContent = response.data;

            await this.collectImage();
            await this.collectSummary();
            await this.collectContent();

            this.loading = false;
            this.reportPublished = true;
        }
    },
    async mounted() {
        await this.getReport();
    }
}
</script>

<style lang="less" scoped>
.order-report {
    background: #fff;
    padding-left: 0;
    padding-right: 0;

    .order-report-base-info {
        /deep/.ant-descriptions-view {
            border-radius: 8px;
        }
    }

    .order-report-group-table-sub {
        /deep/ .ant-table-bordered .ant-table-body>table {
            border-top: 1px solid #e8e8e8 !important;
            border-left: 1px solid #e8e8e8 !important;
        }
    }

    .order-report-group-table {
        /deep/ .ant-table-tbody .ant-table-row:last-child {
            td:first-child {
                border-bottom-left-radius: 8px;
            }

            td:last-child {
                border-bottom-right-radius: 8px;
            }
        }

        /deep/ .ant-table-bordered .ant-table-title {
            font-weight: 600 !important;
        }

        /deep/ .ant-table-tbody>tr:nth-child(even)>td {
            background: unset;
        }

        /deep/ .ant-table-thead th {
            background: #f2f5fb;
        }
    }

    .order-report-group {
        margin: 15px;
    }

    .card-title {
        color: @font-color;
        font-size: 18px;
        font-weight: bold;
        .relative();

        &-real {
            margin-left: 15px;
        }

        &:before {
            display: block;
            content: " ";
            width: 3px;
            height: 18px;
            background: @primary-color;
            border-radius: 8px;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translate(0, -50%);
        }
    }

    /deep/.list-card {
        margin-bottom: @ec-gutter;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .base-item {
        .base-title {
            width: 35%;
            text-align: right;
            padding: @ec-gutter @ec-gutter * 2;
            background: #f5f5f5;
            border: 1px solid #e9e9e9;
            border-top: none;
            .left();
            min-height: 42px;
        }

        .base-value {
            width: 65%;
            .left();
            border-bottom: 1px solid #e9e9e9;
            .no-wrap();
            padding: @ec-gutter @ec-gutter * 2;
            min-height: 42px;
        }
    }

    .report-btn {
        margin-top: 60px;
        .btn-group(center);
    }

    .report-table {
        /deep/.ant-table-thead {
            background: #f5f5f5;
        }
    }

    .shield-icon {
        display: inline-block;
        width: 30px;
        height: 30px;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        vertical-align: middle;
    }
}

.card-body {
    padding: @ec-gutter20;
}

/deep/.ant-col {
    &:first-child,
    &:nth-child(2) {
        .base-item {
            border-top: 1px solid #e9e9e9;
        }
    }

    &:nth-child(2n) {
        .base-item {
            .base-value {
                border-right: 1px solid #e9e9e9;
            }
        }
    }
}
</style>
